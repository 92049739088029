import React, { useState, useEffect, useRef } from 'react';
import {
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TextField,
    Collapse
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Clear as ClearIcon } from '@mui/icons-material';
import { InformesTableCell, InformesTableRow } from '../../../components/CustomStyles';
import { useResponsive } from '../../../hooks/useResponsive';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useErrors } from '../../../hooks/useErrors';
import InLineError from '../../../components/InLineError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useNumberFormatter from '../../../hooks/useNumberFormatter';

const Row = ({ row, parameters }) => {
    const formatNumber = useNumberFormatter();

    const getCellAlignment = (value) => (typeof value === 'number' ? 'right' : 'left');

    return (
        <InformesTableRow>
            {parameters.map((param, index) => (
                <InformesTableCell key={index} sx={{ textAlign: getCellAlignment(row[param.key]) }}>
                    {typeof row[param.key] === 'number' ? formatNumber(row[param.key]) : row[param.key]}
                </InformesTableCell>
            ))}
        </InformesTableRow>
    );
};

const canFilter = ["id_entidad_contable", "na_entidad", "id_categoria_exposicion", "category_name", "Epigrafe"];

export default function SummaryByEntityTable({ executionYear, executionMonth, processId, initialData, isFirstRender }) {
    const { isBelowXlScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();
    const { getCreditRiskReportByEntity } = reportsCreditRiskService();
    const [parameters, setParameters] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [filters, setFilters] = useState({});
    const [expandedFilters, setExpandedFilters] = useState({});
    const columnRefs = useRef({}); // Referencias para medir anchos iniciales
    const [columnWidths, setColumnWidths] = useState({}); // Anchos iniciales

    useEffect(() => {
        if (processId && !isFirstRender.current) fetchReportData();
    }, [processId]);

    useEffect(() => {
        if (initialData && Object.keys(initialData).length > 0) setReportStates(initialData);
    }, [initialData]);

    useEffect(() => {
        // Medir el ancho inicial de cada columna después de renderizar
        const widths = {};
        for (const key in columnRefs.current) {
            if (columnRefs.current[key]) {
                widths[key] = columnRefs.current[key].offsetWidth;
            }
        }
        setColumnWidths(widths);
    }, [parameters, reportData]);

    const fetchReportData = async () => {
        const response = await getCreditRiskReportByEntity({ year: executionYear, month: executionMonth, process_id: processId });
        setReportStates(response);
    };

    const setReportStates = (data) => {
        if (data.current_values) {
            const { parameters, current_values } = data;
            const sortedParams = Object.keys(parameters).sort((a, b) => parameters[a].column_order - parameters[b].column_order);

            setParameters(sortedParams.map((param) => ({
                key: param,
                name: parameters[param].name
            })));

            setFilters(Object.fromEntries(sortedParams.map((param) => [param, ''])));
            setExpandedFilters(Object.fromEntries(sortedParams.map((param) => [param, false])));
            setReportData(current_values);
        }
    };

    const handleFilterChange = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };

    const handleClearFilter = (key) => {
        setFilters({ ...filters, [key]: '' });
    };

    const toggleFilter = (key) => {
        const isCurrentlyExpanded = expandedFilters[key];
        setExpandedFilters({ ...expandedFilters, [key]: !isCurrentlyExpanded });
    
        // Vaciar el filtro si se colapsa
        if (isCurrentlyExpanded) {
            setFilters({ ...filters, [key]: '' });
        }
    };
    

    const filteredData = reportData.filter((row) =>
        parameters.every((param) =>
            canFilter.includes(param.key)
                ? String(row[param.key] || '').toLowerCase().includes(filters[param.key].toLowerCase())
                : true
        )
    );

    if (loading.getCreditRiskReportByEntity || loading.getLastExecution) return <LoadingSpinner />;
    if (inLineErrors.getCreditRiskReportByEntity) return <InLineError errorMessage={inLineErrors.getCreditRiskReportByEntity} />;

    return (
        <TableContainer component={Paper} sx={{ overflowX: isBelowXlScreen ? 'auto' : 'hidden', height: reportData.length < 30 ? 'auto' : 'calc(100vh - 100px)' }}>
            <Table stickyHeader size="small">
                <TableHead>
                    <InformesTableRow>
                        {parameters.map((param) => (
                            <InformesTableCell
                                key={param.key}
                                ref={(el) => (columnRefs.current[param.key] = el)} // Guardar referencia
                                sx={{
                                    textAlign: 'left',
                                    verticalAlign: 'top',
                                    minWidth: columnWidths[param.key] ? `${columnWidths[param.key]}px` : 'auto',
                                    maxWidth: columnWidths[param.key] ? `${columnWidths[param.key]}px` : 'auto',
                                }}
                            >
                                <Stack direction="column" sx={{mx: '2px'}}>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                        {param.name}
                                        {canFilter.includes(param.key) && (
                                            <IconButton size="small" onClick={() => toggleFilter(param.key)}>
                                                {expandedFilters[param.key] ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                                            </IconButton>
                                        )}
                                    </Stack>
                                    <Collapse in={expandedFilters[param.key]} timeout="auto" unmountOnExit>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            value={filters[param.key]}
                                            onChange={(e) => handleFilterChange(param.key, e.target.value)}
                                            placeholder={`Filtrar ${param.name}`}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton size="small" onClick={() => handleClearFilter(param.key)}>
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                ),
                                                style: { fontSize: '13px', height: '24px' },
                                            }}
                                            sx={{
                                                height: '28px',
                                                width: '100%',
                                                '& .MuiOutlinedInput-root': {
                                                    padding: 0,
                                                },
                                                '& input': {
                                                    padding: '4px',
                                                },
                                            }}
                                        />
                                    </Collapse>
                                </Stack>
                            </InformesTableCell>
                        ))}
                    </InformesTableRow>
                </TableHead>
                <TableBody>
                    {filteredData.length === 0 ? (
                        <InformesTableRow>
                            <InformesTableCell colSpan={parameters.length} sx={{ textAlign: 'center' }}>
                                No hay datos para mostrar
                            </InformesTableCell>
                        </InformesTableRow>
                    ) : (
                        filteredData.map((row, index) => <Row key={index} row={row} parameters={parameters} />)
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
