import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest, isAuthEnabled } from "../config/AuthConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import * as Sentry from '@sentry/react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [tokenExpiry, setTokenExpiry] = useState(() => {
        const value = localStorage.getItem('tokenExpiry');
        return value ? new Date(value) : null;
    });
    const [accessToken, setAccessToken] = useState(() => {
        const value = localStorage.getItem('accessToken');
        return value || null;
    });
    const [account, setAccount] = useState(() => {
        const value = localStorage.getItem('account');
        return value ? JSON.parse(value) : null;
    });

    const isTokenExpired = useCallback(() => {
        if (!tokenExpiry) return true;
        return new Date(tokenExpiry) <= new Date();
    }, [tokenExpiry]);

    const updateTokenState = useCallback((response) => {
        const { accessToken, expiresOn, account } = response;
        setTokenExpiry(expiresOn);
        setAccessToken(accessToken);
        setAccount(account);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('tokenExpiry', expiresOn);
        localStorage.setItem('account', JSON.stringify(account));
        Sentry.setUser({
            id: account.homeAccountId,
            username: account.name,
            email: account.username,
        });
    }, []);

    const clearUserState = useCallback(() => {
        setTokenExpiry(null);
        setAccessToken(null);
        setAccount(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('tokenExpiry');
        localStorage.removeItem('account');
        Sentry.setUser(null);
    }, []);

    const acquireToken = useCallback(async () => {
        if (accounts.length === 0 || inProgress !== 'none') return;

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        try {
            const response = await instance.acquireTokenSilent(request);
            updateTokenState(response);
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(request);
            } else {
                console.error(e);
            }
        }
    }, [accounts, inProgress, instance, updateTokenState]);

    useEffect(() => {
        if (isAuthEnabled && accounts.length === 0 && inProgress === "none") {
            instance.loginRedirect().catch(e => {
                console.error(e);
            });
        }
    }, [accounts, instance, inProgress]);

    return (
        <AuthContext.Provider value={{ accessToken, account, isAuthenticated, isTokenExpired, acquireToken, clearUserState }}>
            {children}
        </AuthContext.Provider>
    );
};