import { React, useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useResponsive } from '../../hooks/useResponsive';
import RiskPonderationEADsTable from './tables/RiskPonderationEADsTable';
import CategoryIndicatorsDonutChart from './CategoryIndicatorsDonutChart';
import CategoriesIndicatorsBarChart from './CategoriesIndicatorsBarChart';




export default function TabCreditCounterpartyRisk({ data }) {    
    const { isBelowMdScreen } = useResponsive();    
    const [expandedSettings, setExpandedSettings] = useState(false);

    useEffect(() => {
        // Check if the screen is below xl and expandedSettings is true
        if (!isBelowMdScreen && expandedSettings) {
            // Set expandedSettings to false
            setExpandedSettings(false);
        }
    }, [isBelowMdScreen]);    

    

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <CategoriesIndicatorsBarChart data={data} />
            <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={6}>
                    <CategoryIndicatorsDonutChart data={data} indicator={"Exposición Original"} />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <CategoryIndicatorsDonutChart data={data} indicator={"APRs"} />
                </Grid>
            </Grid>
            <RiskPonderationEADsTable data={data} />
        </Box>
    );
}