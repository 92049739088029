import { LogLevel } from "@azure/msal-browser";

export const isAuthEnabled = window.REACT_APP_AUTH_ENABLED === undefined ||
                             window.REACT_APP_AUTH_ENABLED === null ||
                             window.REACT_APP_AUTH_ENABLED === "true" ||
                             window.REACT_APP_AUTH_ENABLED === true;

export const msalConfig = {
    auth: {
        clientId: window.REACT_APP_AUTHCONFIG_AUDIENCE,
        authority: window.REACT_APP_AUTHCONFIG_AUTHORITY,
        redirectUri: window.REACT_APP_AUTHCONFIG_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        Sentry.captureException(new Error(message));
                        console.error(message);
                        return;s
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
        allowNativeBroker: false,
    },
};

export const loginRequest = {
    scopes: [window.REACT_APP_AUTHCONFIG_SCOPES]
}