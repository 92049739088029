import { React } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CustomSelect from '../../components/CustomSelect';
import { StyledButton } from '../../components/CustomStyles';
import { StyledDatePicker } from '../../components/CustomStyles';
import { useProcessNames } from '../../hooks/useProcessNames';
import { useUsers } from '../../hooks/useUsers';
import { SELECT_TEXT } from '../../theme';



export default function Filters({filters, fullDates, handleOnChangeFilter, filterProcesses}) {
    const { processNamesList } = useProcessNames()
    const { usersList } = useUsers()

    return (
        <>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xxs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    gx={3}
                >
                    <Typography sx={{ ...SELECT_TEXT, mb: '5px' }}>
                        Fecha de datos
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} >
                        <Box display={'flex'} alignItems="center">
                            <StyledDatePicker 
                                views={['year', 'month']} 
                                value={fullDates.dateDataStart} 
                                onChange={(value) => handleOnChangeFilter("DataStart", value)}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear']
                                    }
                                }}
                                closeOnSelect={true}
                            />
                            <Typography sx={{ marginX: '8px', fontSize: '20px' }}>-</Typography>
                            <StyledDatePicker 
                                views={['year', 'month'] } 
                                value={fullDates.dateDataEnd} 
                                onChange={(value) => handleOnChangeFilter("DataEnd", value)}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear']
                                    }
                                }}
                                closeOnSelect={true}
                            />
                        </Box>
                    </LocalizationProvider>
                </Grid>
                <Grid
                    item
                    xxs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    gx={3}
                >
                    <Typography sx={{ ...SELECT_TEXT, mb: '5px' }}>
                        Fecha de Ejecución
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} >
                        <Box display={'flex'} alignItems="center">
                            <StyledDatePicker 
                                views={['year', 'month']} 
                                value={fullDates.dateExecutionStart} 
                                onChange={(value) => handleOnChangeFilter("ExecutionStart", value)}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear']
                                    }
                                }}
                                closeOnSelect={true}
                            />
                            <Typography sx={{ marginX: '8px', fontSize: '20px' }}>-</Typography>
                            <StyledDatePicker 
                                views={['year', 'month']} 
                                value={fullDates.dateExecutionEnd} 
                                onChange={(value) => handleOnChangeFilter("ExecutionEnd", value)}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear']
                                    }
                                }}
                                closeOnSelect={true}
                            />
                        </Box>
                    </LocalizationProvider>
                </Grid>
                <Grid
                    item
                    xxs={12}
                    sm={5}
                    md={5}
                    lg={6}
                    gx={3}
                >
                    <CustomSelect
                        title={'Proceso'}
                        name={"processType"}
                        selectItems={processNamesList}
                        value={filters.processType}
                        onChange={(e) => handleOnChangeFilter(e.target.name, e.target.value)}
                    />
                </Grid>
                <Grid
                    item
                    xxs={12}
                    sm={5}
                    md={5}
                    lg={5}
                    gx={2}
                >
                    <CustomSelect
                        title={'Usuario'}
                        name={"userName"}
                        selectItems={usersList.length > 0 ? usersList.map(user => user.userName) : []}
                        value={filters.userName}
                        onChange={(e) => handleOnChangeFilter(e.target.name, e.target.value)}
                        multiple
                    />
                </Grid>
                <Grid
                    item
                    xxs={12}
                    sm={2}
                    md={2}
                    lg={1}
                    gx={1}
                    display={'flex'}
                    sx={{ justifyContent: "center", alignItems: 'end' }}
                >
                    <StyledButton
                        onClick={() => filterProcesses({filters: filters})}
                        variant='contained'
                        endIcon={<SearchIcon />}
                    >
                        Buscar
                    </StyledButton>
                </Grid>
            </Grid>
        </>
    );
}