import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, Tabs, Typography, IconButton, Menu, MenuItem, Collapse } from '@mui/material';
import { AntTab } from '../../components/CustomStyles';
import CustomSelect from '../../components/CustomSelect';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import TabCreditRisk from './TabCreditRisk';
import { BLUE, BLACK, CONTENT_BOX, SUBTITLE_TEXT, TITLE_TEXT } from '../../theme';
import {Menu as MenuIcon, Settings as SettingsIcon} from '@mui/icons-material';
import { useResponsive } from '../../hooks/useResponsive';
import SettingsMenu from '../reports/SettingsMenu';
import { useProcessFilters } from '../../hooks/useProcessFilters';
import { lastExecutionService } from '../../services/lastExecutionService';
import { useUsers } from '../../hooks/useUsers';
import dayjs from 'dayjs';
import { cdmCreditRiskService } from '../../services/cdmCreditRisk';
import { useErrors } from '../../hooks/useErrors';
import { processesService } from '../../services/processesService';


const tabMenuOptions = [
    'Riesgo de Crédito y Contraparte',
]

const normativeVersionsList = [
    'CRR2',
    'CRR3'
];


export default function CDMExecutions() {
    const { isBelowMdScreen } = useResponsive();
    // API Services
    const { getLastExecution } = lastExecutionService();
    const { getCdMCreditRiskCategoriesPonderations } = cdmCreditRiskService();
    const { getProcesses } = processesService();
    // Custom Hooks
    const { usersList } = useUsers();
    const { filters, fullDates, handleOnChangeFilter, areAnyFilters } = useProcessFilters({ defaultProcessType: "Riesgo de Crédito y Contraparte", defaultState: "Completado", defaultIncludeComments: "False", defaultIncludeFiles: "False" });
    // States
    const [selectedExecution, setSelectedExecution] = useState("");
    const [executionsList, setExecutionsList] = useState([]);
    const [executionDateIdDict, setExecutionDateIdDict] = useState({});
    const [executionsTooltips, setExecutionsTooltips] = useState({});
    const [executionReportData, setExecutionReportData] = useState({});
    const [tab, setTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    // Refs
    const isFirstRender = useRef(true);


    useEffect(() => {
        fetchInitialProcessData();
    }, []);

    useEffect(() => {
        if (areAnyFilters(["includeComments", "includeFiles", "state", "processType"])) {
            if (isFirstRender.current) {
                isFirstRender.current = false;
            }
            else {
                fetchFilteredExecutions();
            }
        }
        
    }, [filters]);


    const fetchInitialProcessData = async () => {
        try {
            // Buscar el último proceso de Riesgo de Crédito y Contraparte ejecutado con estado Completado para mostrarlo cuando se inicia el componente
            const lastExecutions = (await getLastExecution({ ...filters, screen_page: "cdm-executions", report_structure: "summary-categories-ponderations" }));
            if (Object.keys(lastExecutions).length !== 0) {
                handleOnChangeFilter("userName", [lastExecutions.last_process.user]);
                handleOnChangeFilter("normativeVersion", lastExecutions.last_process.version);
                handleOnChangeFilter("DataStart", dayjs(lastExecutions.last_process.data_date + "-01"));
                handleOnChangeFilter("ExecutionStart", dayjs(lastExecutions.last_process.execution_year + "-" + lastExecutions.last_process.execution_month + "-01"));
                setSelectedExecution(lastExecutions.last_process.execution_date.split('.')[0]);
                setExecutionReportData(lastExecutions.last_process.report_content)
                setRelatedExecutionsData(lastExecutions.related_processes);
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };

    const fetchFilteredExecutions = async () => {
        try {
            const executions = (await getProcesses({filters: filters, limit: 100, start: 0})).results;
            if (executions && executions.length > 0) {
                setRelatedExecutionsData(executions);
            }
            else {
                setExecutionsList([]);
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };

    const setRelatedExecutionsData = (related_processes) => {
        setExecutionsList(related_processes.map(execution => execution.execution_date.split('.')[0]));
        setExecutionDateIdDict(related_processes.reduce((acc, item) => {
            acc[item.execution_date.split('.')[0]] = item.process_id;
            return acc;
        }, {}))
        setExecutionsTooltips(related_processes.reduce((acc, item) => {
            const executionDate = item.execution_date.split('.')[0];
            const formattedString = `
                {bold}ID:{bold} ${item.process_id}{break}
                {bold}Fecha de datos:{bold} ${item.data_date}{break}
                {bold}Fecha de ejecución:{bold} ${item.execution_year}-${item.execution_month}-${item.execution_date.split('-')[2]}{break}
                {bold}Hora de ejecución:{bold} ${item.execution_date.split('-')[3].split('.')[0]}{break}
                {bold}Versión normativa:{bold} ${item.version}{break}
                {bold}Usuario:{bold} ${item.user}
            `.replace(/\s+/g, ' '); // Remover espacios innecesarios
        
            acc[executionDate] = formattedString;
            return acc;
        }, {}))
    }


    const fetchReportData = async (newSelectedExecution) => {
        try {
            const reportData = await getCdMCreditRiskCategoriesPonderations({
                process_id: executionDateIdDict[newSelectedExecution],
                year: filters.yearExecutionStart,
                month: filters.monthExecutionStart,
            });
            if (reportData) {
                setExecutionReportData(reportData);
            }
        } catch (error) {
            console.error("Error fetching process data:", error);
        }
    };
    

    const handleOnChangeSelectedExecution = (value) => {
        setSelectedExecution(value);
        fetchReportData(value);
    };

    const changeFilter = (name, value) => { 
        setSelectedExecution("");
        setExecutionsList([]);
        handleOnChangeFilter(name, value);
    };

    const handleChangeTab = (_event, value) => {
        // setTab(value);
        setTab(0);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setTab(index);
        handleMenuClose();
    };


    return (
        <>
            <Box
                sx={{
                    ...CONTENT_BOX,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3 // Espaciado entre elementos
                }}
            >
                <Typography
                    color={BLUE}
                    sx={TITLE_TEXT}
                >
                    Resultados
                </Typography>
                <SettingsMenu
                    filters={filters}
                    fullDates={fullDates}
                    usersList={usersList}
                    selectedExecution={selectedExecution}
                    executionsList={executionsList}
                    executionsTooltips={executionsTooltips}
                    changeFilter={changeFilter}
                    handleOnChangeSelectedExecution={handleOnChangeSelectedExecution}
                    normativeVersionsList={normativeVersionsList}
                />
            </Box >

            {!isBelowMdScreen && (
                <Tabs 
                    sx={{ml: '66px', mb: '0px', minHeight: "38px", height: "38px"}}
                    value={tab}
                    onChange={handleChangeTab}
                >
                    <AntTab
                        tab={0}
                        label={tabMenuOptions[0]}
                    />
                </Tabs>
            )}
            <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                {isBelowMdScreen && (
                    <Box display={'flex'}>
                        <Typography
                            sx={{...TITLE_TEXT, mt: '9px'}}
                            color={BLUE}
                        >
                            {tabMenuOptions[tab]}
                        </Typography>
                        <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem 
                                onClick={() => handleMenuItemClick(0)}
                                sx={{ color: tab === 0 ? BLUE : BLACK }}
                            >
                                {tabMenuOptions[0]}
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
                {tab === 0 && (
                    <Box>
                        <TabCreditRisk data={executionReportData}/>
                        
                    </Box>
                )} 
            </Box>
        </>
    );
}