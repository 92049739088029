import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { COLOR_PALETTE } from '../../../theme';

const DonutChart = ({ title, seriesData, labels }) => {
    // Calculamos el total de la serie para luego obtener el porcentaje
    const total = seriesData.reduce((acc, val) => acc + val, 0);

    const options = {
        chart: {
            type: 'donut',
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '20%',
                },
            },
        },
        labels: labels,
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '13px',
                fontWeight: 'semi-bold',
            },
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
        },
        title: {
            text: title,
            align: 'center',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
            },
        },
        tooltip: {
            y: {
                formatter: function (val, opts) {
                    const percentage = ((val / total) * 100).toFixed(1); // Calculamos el porcentaje
                    return `${val.toLocaleString("es-ES", { minimumFractionDigits: 0, maximumFractionDigits: 2 })}€ (${percentage}%)`; // Mostramos el valor y el porcentaje
                },
            },
        },
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }
        },
        colors: COLOR_PALETTE
    };

    return (
        <div id="chart" style={{width: "100%"}}>
            <ReactApexChart options={options} series={seriesData} type="donut" height={350} width="100%" />
        </div>
    );
};

export default DonutChart;
