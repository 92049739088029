import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { InformesTableCell, InformesTableRow } from "../../../components/CustomStyles";
import { margin, styled } from "@mui/system";
import { GREY } from "../../../theme";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const StyledTotalRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        backgroundColor: GREY,
        fontWeight: 'bold',
    },
}));

const expositions_data = {
    "columns": {
        "category": {
            "name": "Categoría",
            "column_order": 1,
            "type": "string"
        },
        "subcategory": {
            "name": "Subcategoría",
            "column_order": 2,
            "type": "string"
        },
        "original_exposure_balance": {
            "name": "Exposición original (balance)",
            "column_order": 3,
            "type": "float"
        },
        "original_exposure_off_balance": {
            "name": "Exposición original (fuera balance)",
            "column_order": 4,
            "type": "float"
        },
        "irb_weighting": {
            "name": "Ponderación IRB",
            "column_order": 5,
            "type": "float"
        },
        "aprs": {
            "name": "APRs",
            "column_order": 6,
            "type": "float"
        },
        "default_indicator": {
            "name": "Indicador morosidad",
            "column_order": 7,
            "type": "string"
        },
        "pd": {
            "name": "PD",
            "column_order": 8,
            "type": "float"
        },
        "lgd": {
            "name": "LGD",
            "column_order": 9,
            "type": "float"
        },
        "ccf": {
            "name": "CCF",
            "column_order": 10,
            "type": "float"
        },
        "correlation_coefficient_r": {
            "name": "Coeficiente de correlación (R)",
            "column_order": 11,
            "type": "string"
        },
        "elbe": {
            "name": "ELBE",
            "column_order": 12,
            "type": "string"
        },
        "lgd_id": {
            "name": "LGD ID",
            "column_order": 13,
            "type": "string"
        },
        "ccf_std": {
            "name": "CCF (std)",
            "column_order": 14,
            "type": "float"
        },
        "floor_pd": {
            "name": "Floor PD",
            "column_order": 15,
            "type": "float"
        },
        "floor_lgd": {
            "name": "Floor LGD",
            "column_order": 16,
            "type": "float"
        },
        "floor_ccf": {
            "name": "Floor CCF",
            "column_order": 17,
            "type": "float"
        }
    },
    "current_values": [
        {
            "row_order": 1,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 6168722.2,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "25.59%",
            "aprs": 1578452.643125,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "1.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "10.19%",
            "lgd_id": "12.24%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 2,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 3032167.55,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "111.40%",
            "aprs": 3377834.6499999994,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "8.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "35.74%",
            "lgd_id": "44.65%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 3,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 379029424.45,
            "original_exposure_off_balance": 3121798.9000000004,
            "irb_weighting": "3.18%",
            "aprs": 12163873.559999999,
            "default_indicator": "No",
            "pd": "0.11%",
            "lgd": "12.18%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 4,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 990581.9,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "73.50%",
            "aprs": 728077.69625,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "30.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "71.03%",
            "lgd_id": "76.91%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 5,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 1426774.0,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "73.84%",
            "aprs": 1053501.38625,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "13.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "33.60%",
            "lgd_id": "39.50%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 6,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 923631285.9000001,
            "original_exposure_off_balance": 802787.7000000001,
            "irb_weighting": "8.99%",
            "aprs": 83106623.3075,
            "default_indicator": "No",
            "pd": "0.17%",
            "lgd": "21.50%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 7,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 285812150.85,
            "original_exposure_off_balance": 355719.0,
            "irb_weighting": "17.40%",
            "aprs": 49787486.02250001,
            "default_indicator": "No",
            "pd": "0.41%",
            "lgd": "21.50%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 8,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 6539020.45,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "27.47%",
            "aprs": 1796595.8693749998,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "8.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "24.86%",
            "lgd_id": "27.06%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 9,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 2204223.2,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "54.61%",
            "aprs": 1203792.41625,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "11.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "32.26%",
            "lgd_id": "36.63%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 10,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 883815.3999999999,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "47.62%",
            "aprs": 420917.084375,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "27.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "48.92%",
            "lgd_id": "52.73%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 11,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 832015741.85,
            "original_exposure_off_balance": 10882952.4,
            "irb_weighting": "2.40%",
            "aprs": 20254855.8625,
            "default_indicator": "No",
            "pd": "0.08%",
            "lgd": "12.18%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 12,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 997018.9500000001,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "112.90%",
            "aprs": 1125634.3943750001,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "19.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "61.29%",
            "lgd_id": "70.32%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 13,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales: Pymes",
            "original_exposure_balance": 3535994.95,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "45.44%",
            "aprs": 1606685.385,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "7.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "53.08%",
            "lgd_id": "56.71%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 14,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 335173976.75,
            "original_exposure_off_balance": 589067.65,
            "irb_weighting": "6.47%",
            "aprs": 21713796.1125,
            "default_indicator": "No",
            "pd": "0.11%",
            "lgd": "21.50%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 15,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 24230919.849999998,
            "original_exposure_off_balance": 27497.15,
            "irb_weighting": "47.92%",
            "aprs": 11624148.263125,
            "default_indicator": "No",
            "pd": "1.86%",
            "lgd": "21.50%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 16,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 1342316.0,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "73.88%",
            "aprs": 991635.9450000001,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "4.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "48.10%",
            "lgd_id": "54.01%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 17,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 119346353.75,
            "original_exposure_off_balance": 900000.0,
            "irb_weighting": "25.20%",
            "aprs": 30296068.84375,
            "default_indicator": "No",
            "pd": "1.11%",
            "lgd": "16.92%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 18,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 5834519.449999999,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "36.05%",
            "aprs": 2103344.265625,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "6.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "17.49%",
            "lgd_id": "20.37%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 19,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 107947485.75,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "45.95%",
            "aprs": 49604028.660000004,
            "default_indicator": "No",
            "pd": "1.11%",
            "lgd": "25.80%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 20,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 1656399.5,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "55.26%",
            "aprs": 915376.056875,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "15.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "58.07%",
            "lgd_id": "62.49%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 21,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 1582931.6500000001,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "35.31%",
            "aprs": 558980.6543749999,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "21.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "36.71%",
            "lgd_id": "39.54%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 22,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 394376107.70000005,
            "original_exposure_off_balance": 1710967.9500000002,
            "irb_weighting": "12.12%",
            "aprs": 48017636.2275,
            "default_indicator": "No",
            "pd": "0.38%",
            "lgd": "16.92%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 23,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 1401186.4000000001,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "199.12%",
            "aprs": 2790112.419375,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "17.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "32.00%",
            "lgd_id": "47.93%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 24,
            "category": "Exposicion frente a empresas",
            "subcategory": "Empresas Generales:No  Pymes",
            "original_exposure_balance": 1976882.3499999999,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "72.49%",
            "aprs": 1433002.47875,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "17.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "35.76%",
            "lgd_id": "41.56%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 25,
            "category": "Exposicion minoristas",
            "subcategory": "Exposiciones garantizadas por bienes inmuebles residenciales",
            "original_exposure_balance": 31066157.200000003,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "63.83%",
            "aprs": 19829217.481250003,
            "default_indicator": "No",
            "pd": "1.86%",
            "lgd": "25.80%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 26,
            "category": "Exposicion minoristas",
            "subcategory": "Exposiciones garantizadas por bienes inmuebles residenciales",
            "original_exposure_balance": 43011064.95,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "102.04%",
            "aprs": 43889350.903749995,
            "default_indicator": "No",
            "pd": "17.28%",
            "lgd": "16.92%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 27,
            "category": "Exposicion minoristas",
            "subcategory": "Exposiciones garantizadas por bienes inmuebles residenciales",
            "original_exposure_balance": 200172.8,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "23.94%",
            "aprs": 47927.37375,
            "default_indicator": "No",
            "pd": "13.74%",
            "lgd": "12.18%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.0310595,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "0.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 28,
            "category": "Exposicion minoristas",
            "subcategory": "Exposiciones garantizadas por bienes inmuebles residenciales",
            "original_exposure_balance": 172564.65,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "16.80%",
            "aprs": 28997.765,
            "default_indicator": "No",
            "pd": "2.30%",
            "lgd": "12.18%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.0881682,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "0.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 29,
            "category": "Exposicion minoristas",
            "subcategory": "Exposiciones garantizadas por bienes inmuebles residenciales",
            "original_exposure_balance": 1435111.15,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "53.60%",
            "aprs": 769219.5762500002,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "29.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "58.78%",
            "lgd_id": "63.07%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 30,
            "category": "Exposicion minoristas",
            "subcategory": "Exposiciones garantizadas por bienes inmuebles residenciales",
            "original_exposure_balance": 557972.8500000001,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "81.15%",
            "aprs": 452794.96749999997,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "24.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "64.44%",
            "lgd_id": "70.93%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 31,
            "category": "Exposicion minoristas",
            "subcategory": "Exposiciones garantizadas por bienes inmuebles residenciales",
            "original_exposure_balance": 311409.15,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "92.42%",
            "aprs": 287819.906875,
            "default_indicator": "Sí",
            "pd": "100.00%",
            "lgd": "15.00%",
            "ccf": "100.00%",
            "correlation_coefficient_r": "N.a.",
            "elbe": "28.14%",
            "lgd_id": "35.53%",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 32,
            "category": "Exposicion minoristas",
            "subcategory": "Exposiciones garantizadas por bienes inmuebles residenciales",
            "original_exposure_balance": 35273538.35,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "28.59%",
            "aprs": 9140470.595,
            "default_indicator": "No",
            "pd": "0.38%",
            "lgd": "39.78%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.15,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "5.00%",
            "floor_ccf": "50.00%"
        },
        {
            "row_order": 33,
            "category": "Exposicion minoristas",
            "subcategory": "Exposiciones garantizadas por bienes inmuebles residenciales",
            "original_exposure_balance": 2906542.7,
            "original_exposure_off_balance": 0.0,
            "irb_weighting": "38.39%",
            "aprs": 1115850.8074999999,
            "default_indicator": "No",
            "pd": "5.70%",
            "lgd": "21.50%",
            "ccf": "100.00%",
            "correlation_coefficient_r": 0.0476619,
            "elbe": "N.a.",
            "lgd_id": "N.a.",
            "ccf_std": "100.00%",
            "floor_pd": "0.05%",
            "floor_lgd": "0.00%",
            "floor_ccf": "50.00%"
        }
    ]
}



export default function SummaryTable() {
    const formatNumber = useNumberFormatter();

    const orderedColumns = Object.entries(expositions_data.columns)
        .sort((a, b) => a[1].column_order - b[1].column_order)
        .map(([key, value]) => ({ key, name: value.name, type: value.type }));

    const sortedRows = [...expositions_data.current_values].sort((a, b) => a.row_order - b.row_order);

    return (
        <TableContainer component={Paper}>
            <Table>
                {/* Encabezado de la tabla */}
                <TableHead>
                    <InformesTableRow>
                        {orderedColumns.map(({ key, name }) => (
                            <InformesTableCell 
                                key={key} 
                                style={{ 
                                    textAlign: "left",  
                                    verticalAlign: "top"
                                }}
                            >
                                {name}
                            </InformesTableCell>
                        ))}
                    </InformesTableRow>
                </TableHead>

                {/* Cuerpo de la tabla */}
                <TableBody>
                    {sortedRows.map((row, index) => (
                        <InformesTableRow 
                            key={index} 
                        >
                            {orderedColumns.map(({ key }) => (
                                <InformesTableCell
                                    key={key}
                                >
                                    {typeof row[key] === 'number' ? formatNumber(row[key]) : row[key]}
                                </InformesTableCell>
                            ))}
                        </InformesTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};