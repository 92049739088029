import { React, useState, useEffect, useRef } from 'react';
import { Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from '@mui/material';
import { HelpOutlineOutlined, Cancel, FileDownloadOutlined } from '@mui/icons-material';
import { BLUE, BLACK, CONTENT_BOX, TITLE_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import dayjs from 'dayjs';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useProcesses } from '../../../hooks/useProcesses';
import { useProcessFilters } from '../../../hooks/useProcessFilters';
import { useUsers } from '../../../hooks/useUsers';
import SettingsMenu from '../SettingsMenu';
import { lastExecutionService } from '../../../services/lastExecutionService';
import { useErrors } from '../../../hooks/useErrors';
import { processesService } from '../../../services/processesService';
import ExpositionsTable from './ExpositionsTable';
import SettingsCRR2vsCRR3 from './SettingsCRR2vsCRR3';


const EXCEL_FILE_PATH = '/Analisis_Impactos_CRR2vsCRR3.xlsx'

export default function CreditRiskReport() {
    const { isBelowXlScreen } = useResponsive();
    const { getExcelFile } = reportsCreditRiskService();
    const { getLastExecution } = lastExecutionService();
    const { getProcesses } = processesService();
    const { usersList } = useUsers();

    // Hook 1: Para el primer conjunto de filtros
    const filtersSet = useProcessFilters({ 
        defaultProcessType: "Riesgo de Crédito y Contraparte", 
        defaultState: "Completado",
        defaultIncludeComments: "False", 
        defaultIncludeFiles: "False" 
    });

    // Estados generales
    const [selectedExecution, setSelectedExecution] = useState("");
    const [executionDateIdDict, setExecutionDateIdDict] = useState({});
    const [executionsList, setExecutionsList] = useState([]);
    const [executionsTooltips, setExecutionsTooltips] = useState({});
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleOnChangeSelectedExecution = (value) => setSelectedExecution(value);

    // 🔹 Función para cambiar filtros en el primer hook
    const changeFilter = (name, value) => {
        setSelectedExecution("");
        setExecutionsList([]);
        filtersSet.handleOnChangeFilter(name, value);
    };


    return (
        <>
            <Box sx={{ ...CONTENT_BOX, display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <Typography sx={{ ...TITLE_TEXT, mt: '9px' }} color={BLUE}>
                            Riesgo de Crédito y Contraparte: Análisis Impacto CRR2 vs CRR3
                        </Typography>
                        <IconButton sx={{ ml: '10px', color: BLUE }} onClick={handleClickOpenDialog}>
                            <HelpOutlineOutlined />
                        </IconButton>
                        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='lg' fullWidth>
                            <DialogTitle color={BLUE} sx={TITLE_TEXT}>
                                Riesgo de Crédito y Contraparte: Análisis Impacto CRR2 vs CRR3
                            </DialogTitle>
                            <DialogContent>
                                {"Fichero donde se incluye el detalle de Riesgo de Crédito comparando resultados de ejecuciones CRR2 y CRR3."}<br />
                            </DialogContent>
                            <DialogActions sx={{ mb: '20px', justifyContent: 'center' }}>
                                <Button variant='contained' sx={{ fontSize: '13px', textTransform: 'none' }} endIcon={<Cancel />} onClick={handleCloseDialog}>
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    <Button
                        variant='contained'
                        endIcon={<FileDownloadOutlined />}
                        sx={{ fontSize: '13px', textTransform: 'none' }}
                        href={EXCEL_FILE_PATH}
                        download={true}
                    >
                        Exportar Excel
                    </Button>
                </Box>

                {/* Primer menú de filtros con el primer conjunto de filtros */}
                <SettingsCRR2vsCRR3
                    filters={filtersSet.filters}
                    fullDates={filtersSet.fullDates}
                    usersList={usersList}
                    selectedExecution={selectedExecution}
                    executionsList={executionsList}
                    executionsTooltips={executionsTooltips}
                    changeFilter={changeFilter}
                    handleOnChangeSelectedExecution={handleOnChangeSelectedExecution}
                />
            </Box>

            <Box sx={{ ...CONTENT_BOX, mt: '0px' }}>
                <ExpositionsTable />
            </Box>
        </>
    );
}
