import React, { useState, useEffect } from 'react';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow, IconButton, Stack, 
        Select, MenuItem, TextField, FormControl, Snackbar, Alert } from '@mui/material';
import { Delete as DeleteIcon, AddCircle as AddIcon, Edit as EditIcon, CheckCircle as CheckIcon, Cancel as CancelIcon } from '@mui/icons-material';
import { StyledTableCell, StyledTableRow } from '../../components/CustomStyles';
import { GREY } from '../../theme';
import { useResponsive } from '../../hooks/useResponsive';
import { useEntitiesConfig } from '../../hooks/useEntitiesConfig';
import { useErrors } from '../../hooks/useErrors';
import LoadingSpinner from '../../components/LoadingSpinner';
import ConfirmDialog from '../../components/ConfirmDialog';
import InLineError from '../../components/InLineError';


const orderedFields = ['id_entidad_contable', 'id_grupo', 'na_entidad', 'ds_tipo_entidad', 'is_reservado'];

const Row = ({ entity, parameters, handleSave, handleDelete, index }) => {
  const [isEditingEntity, setIsEditingEntity] = useState(false);
  const [editedEntity, setEditedEntity] = useState({ ...entity });
  const [openDialog, setOpenDialog] = useState(false); 


  const toggleEditEntity = () => {
    setIsEditingEntity(!isEditingEntity);
    setEditedEntity(entity);
  };

  const handleEditEntity = (event) => {
    const { name, value } = event.target;
    setEditedEntity((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderField = (paramKey) => {
    const param = parameters[paramKey];
    const value = editedEntity[paramKey];
    
    if (isEditingEntity) {
      if (param.type === 'enum' && param.values.length > 0) {
        return (
          <FormControl style={{width: '70%'}}>
            <Select
              name={paramKey}
              value={value}
              onChange={handleEditEntity}
              size="small"
              sx={{ fontSize: '12px', fontWeight: 400, height: '23px', backgroundColor: 'white' }}
            >
              {param.values.map((val) => (
                <MenuItem key={val.value} value={val.value} sx={{ fontSize: '12px', fontWeight: 400 }}>
                  {val.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      } else {
        return (
            <TextField
              name={paramKey}
              value={value}
              onChange={handleEditEntity}
              size="small"
              sx={{ width: '70%', backgroundColor: 'white' }}
              InputProps={{
                style: {
                  fontSize: '12px', 
                  height: '23px' 
                }
              }}
            />
        );
      }
    } else {
      return entity[paramKey];
    }
  };

  const handleSaveClick = () => {
    handleSave(editedEntity, index);
    toggleEditEntity();
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <TableRow sx={{ fontWeight: 700, backgroundColor: GREY, height: '30px' }} >
        {orderedFields.map((key) => (
          <StyledTableCell style={{paddingLeft: '10px'}} key={key}>{renderField(key)}</StyledTableCell>
        ))}
        <StyledTableCell>
          <Stack direction="row" justifyContent="center">
            {isEditingEntity ? (
              <>
                <IconButton size="small" onClick={handleSaveClick}>
                  <CheckIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={toggleEditEntity}>
                  <CancelIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton size="small" onClick={toggleEditEntity}>
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={handleClickOpenDialog}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </Stack>
        </StyledTableCell>
      </TableRow>
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={() => handleDelete(entity.id_entidad_contable)}
        title="Confirmar"
        description="¿Estás seguro de que deseas eliminar esta entidad?"
      />
    </>
  );
}

export default function Entities() {
  const { isBelowLgScreen } = useResponsive();
  const { loading, inLineErrors, setDialogErrorState } = useErrors();
  const { entities, parameters, updateEntities} = useEntitiesConfig();
  const [isAdding, setIsAdding] = useState(false);
  const [newEntity, setNewEntity] = useState({});

  const validateEntity = (entity, index = null) => {
    for (const key in entity) {
      if (entity[key] === "" || entity[key] === null || entity[key].toString().trim() === '') {
        setDialogErrorState('allFieldsObligatory', 'Todos los campos son obligatorios.');
        return false;
      }
    }
    if (entities.some((e, i) => e.id_entidad_contable === entity.id_entidad_contable && i !== index)) {
      setDialogErrorState('repeatedId', 'El ID de entidad contable ya existe.');
      return false;
    }
    return true;
  };

  const handleSave = async (updatedEntity, index) => {
    if (validateEntity(updatedEntity, index)) {
      const updatedEntities = [...entities];
      updatedEntities[index] = updatedEntity;
      await updateEntities(updatedEntities);
    }
    
  };

  const handleDelete = async (id_entidad_contable) => {
    const updatedEntities = entities.filter((entity) => entity.id_entidad_contable !== id_entidad_contable);
    await updateEntities(updatedEntities);
  };

  const handleAdd = async () => {
    if (!validateEntity(newEntity)) {
      return;
    }
    const updatedEntities = [...entities, newEntity];
    await updateEntities(updatedEntities);
    setIsAdding(false);
    setNewEntity({});
  };

  const handleCancelAdd = () => {
    setIsAdding(false);
    setNewEntity({});
  };

  const handleEditField = (event) => {
    const { name, value } = event.target;
    setNewEntity((prev) => ({
      ...prev,
      [name]: value.toString(),
    }));
  };

  const renderField = (paramKey) => {
    const param = parameters[paramKey];
    const value = newEntity[paramKey] || '';

    if (param.type === 'enum' && param.values.length > 0) {
      return (
        <FormControl style={{ width: '70%' }}>
          <Select
            name={paramKey}
            value={value}
            onChange={handleEditField}
            size="small"
            sx={{ fontSize: '12px', fontWeight: 400, height: '23px', backgroundColor: 'white' }}
          >
            {param.values.map((val) => (
              <MenuItem key={val.value} value={val.value} sx={{ fontSize: '12px', fontWeight: 400 }}>
                {val.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <TextField
          autoComplete="off"
          name={paramKey}
          value={value}
          onChange={handleEditField}
          size="small"
          sx={{ width: '70%', backgroundColor: 'white' }}
          InputProps={{
            style: {
              fontSize: '12px',
              height: '23px'
            }
          }}
        />
      );
    }
  };

  if (loading.getEntities) {
    return (
      <LoadingSpinner/>
    )
  }
  else if (inLineErrors.getEntities) {
    return (
      <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getEntities} />
    )
  }
  else {
    return (
      <Box component={Paper} sx={{ boxShadow: 'none' }}>
        <TableContainer component={Paper} sx={{ overflowX: isBelowLgScreen ? 'auto' : 'hidden', maxHeight: 500, overflowY: 'auto', marginBottom: '20px' }}>
          <Table stickyHeader sx={{ borderCollapse: 'separate', borderSpacing: '0 8px', minWidth: '900px' }}>
            <TableHead>
              <StyledTableRow>
                {orderedFields.map((key) => (
                  <StyledTableCell style={{paddingLeft: '10px'}} key={key}>{parameters[key]?.display_name}</StyledTableCell>
                ))}
                <StyledTableCell width={"1%"}>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    {!isAdding && (
                      <IconButton size="small" onClick={() => setIsAdding(true)}>
                        <AddIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {isAdding && (
                <TableRow>
                  {orderedFields.map((key) => (
                    <StyledTableCell key={key}>{renderField(key)}</StyledTableCell>
                  ))}
                  <StyledTableCell>
                    <Stack direction="row" justifyContent="center">
                      <IconButton size="small" onClick={handleAdd}>
                        <CheckIcon fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={handleCancelAdd}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </StyledTableCell>
                </TableRow>
              )}
              {entities.map((entity, index) => (
                <Row
                  key={entity.id_entidad_contable}
                  entity={entity}
                  parameters={parameters}
                  handleSave={handleSave}
                  handleDelete={handleDelete}
                  handleCancelEdit={handleCancelAdd}
                  index={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
}