import { React, useState, useEffect, useRef } from 'react';
import { Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Tabs, Grid, Menu, MenuItem } from '@mui/material';
import { HelpOutlineOutlined, Cancel, FileDownloadOutlined, Menu as MenuIcon } from '@mui/icons-material';
import { BLUE, BLACK, CONTENT_BOX, TITLE_TEXT, SELECT_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import dayjs from 'dayjs';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useProcesses } from '../../../hooks/useProcesses';
import { useProcessFilters } from '../../../hooks/useProcessFilters';
import { useUsers } from '../../../hooks/useUsers';
import SettingsMenu from '../SettingsMenu';
import { lastExecutionService } from '../../../services/lastExecutionService';
import { useErrors } from '../../../hooks/useErrors';
import { processesService } from '../../../services/processesService';
import SummaryTable from './SummaryTable';
import { AntTab, StyledDatePicker } from '../../../components/CustomStyles';
import PrincipalRatiosTable from './PrincipalRatiosTable';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const menuOptions = [
    {
        'tabName': 'Indicadores clave',
        'apiService': '',
    },
    {
        'tabName': 'Principales ratios de capital',
        'apiService': '',
    }
]

const EXCEL_FILE_PATH = '/Resumen_de_indicadores_clave.xlsx'

export default function KeyIndicatorsReport() {
    const { isBelowXlScreen } = useResponsive();
    const [tab, setTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setTab(index);
        handleMenuClose();
    };


    return (
        <>
            <Box sx={{ ...CONTENT_BOX, display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <Typography sx={{ ...TITLE_TEXT, mt: '9px' }} color={BLUE}>
                            Resumen de Indicadores Clave
                        </Typography>
                        <IconButton sx={{ ml: '10px', color: BLUE }} onClick={handleClickOpenDialog}>
                            <HelpOutlineOutlined />
                        </IconButton>
                        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='lg' fullWidth>
                            <DialogTitle color={BLUE} sx={TITLE_TEXT}>
                                Resumen de Indicadores Clave
                            </DialogTitle>
                            <DialogContent>
                                {"Ficheros donde se incluyen el detalle de Resumen de Indicadores Clave de procesos."}<br />
                                <span>&bull;    </span><u>{"Hoja Indicadores clave:"}</u> {" Plantilla de indicadores clave."}{' '}<br />
                                <span>&bull;    </span><u>{"Hoja Principales ratios de capital:"}</u> {" Comparación de los fondos propios y de las ratios de capital y de apalancamiento de las entidades con y sin la aplicación de las disposiciones transitorias de la NIIF 9 o de ECL análogas"}{' '}<br />
                            </DialogContent>
                            <DialogActions sx={{ mb: '20px', justifyContent: 'center' }}>
                                <Button variant='contained' sx={{ fontSize: '13px', textTransform: 'none' }} endIcon={<Cancel />} onClick={handleCloseDialog}>
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                    <Button
                        variant='contained'
                        endIcon={<FileDownloadOutlined />}
                        sx={{ fontSize: '13px', textTransform: 'none' }}
                        href={EXCEL_FILE_PATH}
                        download={true}
                    >
                        Exportar Excel
                    </Button>
                </Box>
            </Box>

            <Box sx={{ ...CONTENT_BOX, mt: '0px' }}>
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid
                        item
                        xxs={12}
                        xs={6}
                        sm={6}
                        md={2}
                    >
                        <Typography sx={{ ...SELECT_TEXT, whiteSpace: 'nowrap' }}>
                            Fecha de datos
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <StyledDatePicker
                                sx={{ mt: '6px' }}
                                views={['year', 'month']} 
                                name={"dataDate"}
                                closeOnSelect={true}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear']
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Box>

            {!isBelowXlScreen &&
            (
                <Box display={'flex'}>
                    <Tabs
                        sx={{ml: '66px', mb: '0px', minHeight: "38px", height: "38px"}} 
                        value={tab} 
                        onChange={handleTabChange}
                    >
                        <AntTab
                            tab={0}
                            label="Indicadores clave" 
                        />
                        <AntTab 
                            tab={1}
                            label="Principales ratios de capital" 
                        />
                    </Tabs>
                </Box>
            )}
                <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                    {isBelowXlScreen && (
                        <Box display={'flex'}>
                            <Typography
                                    sx={{...TITLE_TEXT, mt: '9px'}}
                                    color={BLUE}
                                >
                                    {menuOptions[tab].tabName}
                            </Typography>
                            <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(0)}
                                    sx={{ color: tab === 0 ? BLUE : BLACK }}
                                >
                                    Indicadores clave
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(1)}
                                    sx={{ color: tab === 1 ? BLUE : BLACK }}
                                >
                                    Principales ratios de capital
                                </MenuItem>
                            </Menu>
                            
                        </Box>
                    )}
                    {tab === 0 && (
                        <SummaryTable />
                    )}
                    {tab === 1 && (
                        <PrincipalRatiosTable />
                    )}
                </Box>
        </>
    );
}
