import { useContext, useState } from 'react'
import { ProcessesContext } from '../context/ProcessesContext'

export const useProcessFilters = ({ defaultProcessType = "", defaultState = "", defaultNormativeVersion = "", defaultIncludeComments = "True", defaultIncludeFiles = "True" } = {}) => {
    const { setAppliedFilters } = useContext(ProcessesContext)
    const [filters, setFilters] = useState({
        processType: defaultProcessType,
        state: defaultState,
        includeComments: defaultIncludeComments,
        includeFiles: defaultIncludeFiles,
        monthDataStart: null,
        yearDataStart: null,
        monthDataEnd: null,
        yearDataEnd: null,
        monthExecutionStart: null,
        yearExecutionStart: null,
        monthExecutionEnd: null,
        yearExecutionEnd: null,
        normativeVersion: defaultNormativeVersion,
        userName: [],
    })
    const [fullDates, setFullDates] = useState({
        dateDataStart: null,
        dateDataEnd: null,
        dateExecutionStart: null,
        dateExecutionEnd: null,
    })

    const [openFilters, setOpenFilters] = useState(false)


    const handleOpenFilters = () => {
        setOpenFilters(!openFilters)
    }

    const handleClearFilters = () => {
        setFullDates({
            dateDataStart: null,
            dateDataEnd: null,
            dateExecutionStart: null,
            dateExecutionEnd: null,
        })
        setFilters({
            processType: defaultProcessType,
            state: defaultState,
            includeComments: defaultIncludeComments,
            includeFiles: defaultIncludeFiles,
            monthDataStart: null,
            yearDataStart: null,
            monthDataEnd: null,
            yearDataEnd: null,
            monthExecutionStart: null,
            yearExecutionStart: null,
            monthExecutionEnd: null,
            yearExecutionEnd: null,
            normativeVersion: defaultNormativeVersion,
            userName: [],
        })
        setAppliedFilters({})
    }

    const areAnyFilters = (excludedKeys = []) => {
        return Object.entries(filters)
            .filter(([key]) => !excludedKeys.includes(key)) // Excluir las keys especificadas en `excludedKeys`
            .some(([key, value]) => {
                if (Array.isArray(value)) {
                    // Si es una lista, comprobar si está vacía
                    return value.length > 0;
                }
                // Para otros tipos, comprobar que no sea nulo ni vacío
                return value !== null && value !== "";
            });
    };
    

    const changeMonthStructure = (month) => {
        const changedMonth = String(month + 1).padStart(2, '0')
        return changedMonth
    }

    const handleOnChangeFilter = (name, value) => {
        if (name === "processType" || name === "userName" || name === "normativeVersion") {
            setFilters(prev => ({ ...prev, [name]: value }))
        }
        else {
            setFullDates(prev => ({ ...prev, ["date" + name]: value }))
            if (value) {
                setFilters(prev => ({ ...prev, ["month" + name]: changeMonthStructure(value.$M), ["year" + name]: value.$y }))
            } else {
                setFilters(prev => ({ ...prev, ["month" + name]: null, ["year" + name]: null }))
            }
        }
    }

    return {
        filters,
        fullDates,
        openFilters,
        handleOpenFilters,
        handleClearFilters,
        areAnyFilters,
        handleOnChangeFilter,
        setFilters
    }
}