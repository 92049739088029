import React from 'react'

export function useRenderText () {
    const renderText = (content) => {
        if (!content) return null;
        return content.split("{break}").map((segment, index) => (
            <React.Fragment key={index}>
                {segment.split(/(\{bold\}.*?\{bold\})/g).map((part, i) => {
                    if (part.startsWith("{bold}") && part.endsWith("{bold}")) {
                        const boldText = part.slice(6, -6);
                        return (
                            <b key={i} style={{ fontWeight: 'bold' }}>
                                {boldText}
                            </b>
                        );
                    }
                    return part;
                })}
                {index < content.split("{break}").length - 1 && <br />}
            </React.Fragment>
        ));
    };
  return { renderText }
}