import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { InformesTableCell, InformesTableRow } from "../../../components/CustomStyles";
import { margin, styled } from "@mui/system";
import { GREY } from "../../../theme";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const StyledTotalRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        backgroundColor: GREY,
        fontWeight: 'bold',
    },
}));

const expositions_data = {
    "columns": {
        "row_title": {
            "name": "",
            "column_order": 1,
            "type": "string"
        },
        "amount": {
            "name": "Importe",
            "column_order": 2,
            "type": "float"
        },
        "contracts": {
            "name": "Contratos",
            "column_order": 3,
            "type": "integer"
        },
        "ponderation_CRR2": {
            "name": "Ponderación CRR2",
            "column_order": 4,
            "type": "integer"
        },
        "ponderation_CRR3": {
            "name": "Ponderación CRR3",
            "column_order": 5,
            "type": "integer"
        },
        "reclassification": {
            "name": "Reclasificación",
            "column_order": 6,
            "type": "string"
        },
        "impact": {
            "name": "Impacto",
            "column_order": 7,
            "type": "float"
        }
    },
    "current_values": [
        {
            "row_title": "Exposiciones frente a administraciones regionales o autoridades locales",
            "row_order": 1,
            "level": 1,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "No admisibles y  BIGR (subcategor\u00eda 0911)",
            "row_order": 2,
            "level": 4,
            "amount": -830080.81,
            "contracts": 2.0,
            "ponderation_CRR2": 0.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Hipotecario",
            "impact": -1245121.215
        },
        {
            "row_title": "Exposiciones frente a bancos multilaterales de desarrollo",
            "row_order": 3,
            "level": 1,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Modificaci\u00f3n RW para NCC = '2'",
            "row_order": 4,
            "level": 4,
            "amount": -563.96201,
            "contracts": 1.0,
            "ponderation_CRR2": 50.0,
            "ponderation_CRR3": 30.0,
            "reclassification": null,
            "impact": 112.79240200000001
        },
        {
            "row_title": "Exposiciones frente a entidades",
            "row_order": 5,
            "level": 1,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Sin NCC",
            "row_order": 6,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Sin tratamiento preferencial",
            "row_order": 7,
            "level": 3,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Largo plazo ",
            "row_order": 8,
            "level": 4,
            "amount": -3605097.49835,
            "contracts": 29.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 150.0,
            "reclassification": null,
            "impact": -1802548.749175
        },
        {
            "row_title": "Corto plazo",
            "row_order": 9,
            "level": 4,
            "amount": -656132.08707,
            "contracts": 17.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 75.0,
            "reclassification": null,
            "impact": 164033.02176749997
        },
        {
            "row_title": "Con tratamiento preferencial",
            "row_order": 10,
            "level": 3,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Grado A (tratamiento preferente)",
            "row_order": 11,
            "level": 4,
            "amount": -197.28,
            "contracts": 3.0,
            "ponderation_CRR2": 20.0,
            "ponderation_CRR3": 30.0,
            "reclassification": null,
            "impact": -19.727999999999994
        },
        {
            "row_title": "Grado A Largo plazo",
            "row_order": 12,
            "level": 4,
            "amount": -292089701.39,
            "contracts": 172.0,
            "ponderation_CRR2": 20.0,
            "ponderation_CRR3": 40.0,
            "reclassification": null,
            "impact": -58417940.278
        },
        {
            "row_title": "Diferencias fecha de vencimiento",
            "row_order": 13,
            "level": 4,
            "amount": -718663.28,
            "contracts": 5.0,
            "ponderation_CRR2": 20.0,
            "ponderation_CRR3": 50.0,
            "reclassification": null,
            "impact": -215598.984
        },
        {
            "row_title": "Sin grado (Largo plazo)",
            "row_order": 14,
            "level": 4,
            "amount": -45346465.14817001,
            "contracts": 25.0,
            "ponderation_CRR2": 20.0,
            "ponderation_CRR3": 150.0,
            "reclassification": null,
            "impact": -58950404.69262102
        },
        {
            "row_title": "Sin grado (Corto plazo)",
            "row_order": 15,
            "level": 4,
            "amount": -16918184.32,
            "contracts": 16.0,
            "ponderation_CRR2": 20.0,
            "ponderation_CRR3": 75.0,
            "reclassification": null,
            "impact": -9305001.376
        },
        {
            "row_title": "Con NCC",
            "row_order": 16,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Cambio de NCC = '2'",
            "row_order": 17,
            "level": 4,
            "amount": -1409626541.3867102,
            "contracts": 37.0,
            "ponderation_CRR2": 50.0,
            "ponderation_CRR3": 30.0,
            "reclassification": null,
            "impact": 281925308.277342
        },
        {
            "row_title": "Ranking.Estructura.Deuda",
            "row_order": 18,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Deuda subordinada",
            "row_order": 19,
            "level": 4,
            "amount": -88828782.55999999,
            "contracts": 9.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Deuda subordinada",
            "impact": -44414391.27999999
        },
        {
            "row_title": "Deuda subordinada",
            "row_order": 20,
            "level": 4,
            "amount": -9667039.35,
            "contracts": 1.0,
            "ponderation_CRR2": 20.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Deuda subordinada",
            "impact": -12567151.154999997
        },
        {
            "row_title": "Deuda subordinada",
            "row_order": 21,
            "level": 4,
            "amount": -146069794.22000003,
            "contracts": 20.0,
            "ponderation_CRR2": 50.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Deuda subordinada",
            "impact": -146069794.22000003
        },
        {
            "row_title": "Renta variable",
            "row_order": 22,
            "level": 4,
            "amount": -18188550.83,
            "contracts": 2.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 100.0,
            "reclassification": "Renta variable",
            "impact": 0.0
        },
        {
            "row_title": "Exposiciones frente a empresas",
            "row_order": 23,
            "level": 1,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Con NCC",
            "row_order": 24,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Modificaci\u00f3n RW para NCC = '3'",
            "row_order": 25,
            "level": 4,
            "amount": -601145451.3695301,
            "contracts": 108.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 75.0,
            "reclassification": null,
            "impact": 150286362.84238255
        },
        {
            "row_title": "Sin NCC",
            "row_order": 26,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Exposiciones de financiaci\u00f3n especializada",
            "row_order": 27,
            "level": 3,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Financiaci\u00f3n de proyectos en fase operativa. Alta calidad",
            "row_order": 28,
            "level": 4,
            "amount": -3654234.02,
            "contracts": 1.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 80.0,
            "reclassification": null,
            "impact": 730846.804
        },
        {
            "row_title": "Reclasificaci\u00f3n a Minorista",
            "row_order": 29,
            "level": 3,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "PF > 1M deuda",
            "row_order": 30,
            "level": 4,
            "amount": -587180233.4000001,
            "contracts": 2745.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 100.0,
            "reclassification": "Minorista",
            "impact": 0.0
        },
        {
            "row_title": "PF > 1M deuda (con desfase divisa)",
            "row_order": 31,
            "level": 4,
            "amount": -7.21,
            "contracts": 2.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Minorista",
            "impact": -3.6049999999999995
        },
        {
            "row_title": "GE en CRR2, pasan a ser Pyme en CRR3 y deuda < 1M (Transaccionistas)",
            "row_order": 32,
            "level": 4,
            "amount": -554990.0,
            "contracts": 86.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 45.0,
            "reclassification": "Minorista",
            "impact": 305244.5
        },
        {
            "row_title": "GE en CRR2, pasan a ser Pyme en CRR3 y deuda < 1M",
            "row_order": 33,
            "level": 4,
            "amount": -216969673.4,
            "contracts": 1506.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 75.0,
            "reclassification": "Minorista",
            "impact": 54242418.349999994
        },
        {
            "row_title": "M\u00e9todo completo",
            "row_order": 34,
            "level": 3,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Garant\u00eda comercial: LTV < 60%",
            "row_order": 35,
            "level": 4,
            "amount": -17976909.47,
            "contracts": 23.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 70.0,
            "reclassification": "Hipotecario",
            "impact": 5393072.841
        },
        {
            "row_title": "Garant\u00eda comercial: 60% < LTV > 80%",
            "row_order": 36,
            "level": 4,
            "amount": -6835938.69,
            "contracts": 8.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 90.0,
            "reclassification": "Hipotecario",
            "impact": 683593.869
        },
        {
            "row_title": "Garant\u00eda comercial: LTV > 80%",
            "row_order": 37,
            "level": 4,
            "amount": -2958938.1,
            "contracts": 4.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 110.0,
            "reclassification": "Hipotecario",
            "impact": -295893.81000000006
        },
        {
            "row_title": "Exp BIGR no cumplen cond. Art124.3",
            "row_order": 38,
            "level": 4,
            "amount": -240681497.72,
            "contracts": 138.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Hipotecario",
            "impact": -120340748.85999998
        },
        {
            "row_title": "Ranking.Estructura.Deuda",
            "row_order": 39,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Deuda subordinada",
            "row_order": 40,
            "level": 4,
            "amount": -44468902.22,
            "contracts": 4.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Deuda subordinada",
            "impact": -22234451.11
        },
        {
            "row_title": "Deuda subordinada",
            "row_order": 41,
            "level": 4,
            "amount": -5808187.3,
            "contracts": 1.0,
            "ponderation_CRR2": 50.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Deuda subordinada",
            "impact": -5808187.299999999
        },
        {
            "row_title": "Exposiciones minoristas",
            "row_order": 42,
            "level": 1,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Minoristas",
            "row_order": 43,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Transaccionistas",
            "row_order": 44,
            "level": 4,
            "amount": -538715761.8,
            "contracts": 314643.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 45.0,
            "reclassification": null,
            "impact": 161614728.54
        },
        {
            "row_title": "Transaccionistas (con desfase divisa)",
            "row_order": 45,
            "level": 4,
            "amount": -3730758.92,
            "contracts": 2062.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 67.5,
            "reclassification": null,
            "impact": 279806.91899999976
        },
        {
            "row_title": "PF > 1M (Aumento de deuda)",
            "row_order": 46,
            "level": 4,
            "amount": -8218952.52924,
            "contracts": 102.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 100.0,
            "reclassification": null,
            "impact": -2054738.1323100002
        },
        {
            "row_title": "Minorista con desfase de divisa",
            "row_order": 47,
            "level": 4,
            "amount": -190814138.34736,
            "contracts": 10720.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 112.5,
            "reclassification": null,
            "impact": -71555301.88025999
        },
        {
            "row_title": "PF > 1M (Aumento de deuda y con desfase de divisa)",
            "row_order": 48,
            "level": 4,
            "amount": -0.97,
            "contracts": 1.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 150.0,
            "reclassification": null,
            "impact": -0.7275
        },
        {
            "row_title": "Reclasificaci\u00f3n a Empresas",
            "row_order": 49,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Dejan de ser pyme en CRR3 o se mantienen pyme pero deuda pasa a > 1M",
            "row_order": 50,
            "level": 4,
            "amount": -176275137.13,
            "contracts": 1837.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 100.0,
            "reclassification": "Empresas",
            "impact": -44068784.2825
        },
        {
            "row_title": "M\u00e9todo completo",
            "row_order": 51,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "AUE o Exp BIGR no cumplen cond. Art124.3",
            "row_order": 52,
            "level": 4,
            "amount": -5784241.9799999995,
            "contracts": 36.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 110.0,
            "reclassification": "Hipotecario",
            "impact": -2024484.693000001
        },
        {
            "row_title": "Garant\u00eda residencual: LTV < 30%",
            "row_order": 53,
            "level": 4,
            "amount": -145001389.89,
            "contracts": 1170.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Hipotecario",
            "impact": -108751042.41749999
        },
        {
            "row_title": "Garant\u00eda residencial: 60% < LTV > 80%",
            "row_order": 54,
            "level": 4,
            "amount": -1327396.23,
            "contracts": 11.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 30.0,
            "reclassification": "Hipotecario",
            "impact": 597328.3034999999
        },
        {
            "row_title": "Garant\u00eda comercial: LTV < 60%",
            "row_order": 55,
            "level": 4,
            "amount": -70041.82,
            "contracts": 1.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 45.0,
            "reclassification": "Hipotecario",
            "impact": 21012.546000000002
        },
        {
            "row_title": "Garant\u00eda comercial: 60% < LTV > 80%",
            "row_order": 56,
            "level": 4,
            "amount": -64578612.35,
            "contracts": 937.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 70.0,
            "reclassification": "Hipotecario",
            "impact": 3228930.617500007
        },
        {
            "row_title": "Garant\u00eda comercial: LTV > 80%",
            "row_order": 57,
            "level": 4,
            "amount": -8684744.870000001,
            "contracts": 83.0,
            "ponderation_CRR2": 75.0,
            "ponderation_CRR3": 90.0,
            "reclassification": "Hipotecario",
            "impact": -1302711.7305000005
        },
        {
            "row_title": "Exposiciones garantizadas por hipotecas sobre bienes inmuebles",
            "row_order": 58,
            "level": 1,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Arrendamientos financieros",
            "row_order": 59,
            "level": 4,
            "amount": -6245286.85,
            "contracts": 8.0,
            "ponderation_CRR2": 50.0,
            "ponderation_CRR3": 100.0,
            "reclassification": "Empresas",
            "impact": -3122643.425
        },
        {
            "row_title": "Arrendamientos financieros",
            "row_order": 60,
            "level": 4,
            "amount": -1338213.01571,
            "contracts": 80.0,
            "ponderation_CRR2": 50.0,
            "ponderation_CRR3": 75.0,
            "reclassification": "Minorista",
            "impact": -334553.2539275
        },
        {
            "row_title": "Arrendamientos financieros",
            "row_order": 61,
            "level": 4,
            "amount": -51296.61,
            "contracts": 2.0,
            "ponderation_CRR2": 50.0,
            "ponderation_CRR3": 100.0,
            "reclassification": "Impago",
            "impact": -25648.305
        },
        {
            "row_title": "Exposiciones en situaci\u00f3n de impago",
            "row_order": 62,
            "level": 1,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Provision <20",
            "row_order": 63,
            "level": 4,
            "amount": -99451349.83000001,
            "contracts": 1088.0,
            "ponderation_CRR2": 100.0,
            "ponderation_CRR3": 150.0,
            "reclassification": null,
            "impact": -49725674.91499999
        },
        {
            "row_title": "Provision >20",
            "row_order": 64,
            "level": 4,
            "amount": -328915.01,
            "contracts": 3.0,
            "ponderation_CRR2": 150.0,
            "ponderation_CRR3": 100.0,
            "reclassification": null,
            "impact": 164457.505
        },
        {
            "row_title": "Elementos asociados a riesgos especialmente elevados",
            "row_order": 65,
            "level": 1,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Capital Riesgo",
            "row_order": 66,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Capital Riesgo: Pr\u00e9stamos ",
            "row_order": 67,
            "level": 4,
            "amount": -150393072.70119002,
            "contracts": 4.0,
            "ponderation_CRR2": 150.0,
            "ponderation_CRR3": 100.0,
            "reclassification": "Empresas",
            "impact": 75196536.35059503
        },
        {
            "row_title": "Capital Riesgo: Pr\u00e9stamos ",
            "row_order": 68,
            "level": 4,
            "amount": 496767.8,
            "contracts": 1.0,
            "ponderation_CRR2": 150.0,
            "ponderation_CRR3": 75.0,
            "reclassification": "Minorista",
            "impact": -372575.85
        },
        {
            "row_title": "Capital Riesgo: Inversiones especulativas y Resto",
            "row_order": 69,
            "level": 4,
            "amount": -2093635.37247,
            "contracts": 9.0,
            "ponderation_CRR2": 150.0,
            "ponderation_CRR3": 100.0,
            "reclassification": "Renta variable",
            "impact": 1046817.6862350001
        },
        {
            "row_title": "Exposiciones AUE",
            "row_order": 70,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Exposiciones AUE y rw.pref. Vacio",
            "row_order": 71,
            "level": 4,
            "amount": -208007446.41000003,
            "contracts": 615.0,
            "ponderation_CRR2": 150.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Hipotecario",
            "impact": 0.0
        },
        {
            "row_title": "Promotores",
            "row_order": 72,
            "level": 2,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Provision > 20%",
            "row_order": 73,
            "level": 4,
            "amount": -26682606.68,
            "contracts": 66.0,
            "ponderation_CRR2": 150.0,
            "ponderation_CRR3": 100.0,
            "reclassification": "Impago",
            "impact": 13341303.339999996
        },
        {
            "row_title": "AUE morosos",
            "row_order": 74,
            "level": 4,
            "amount": -12701088.01,
            "contracts": 20.0,
            "ponderation_CRR2": 150.0,
            "ponderation_CRR3": 150.0,
            "reclassification": "Impago",
            "impact": 0.0
        },
        {
            "row_title": "Exposiciones de renta variable",
            "row_order": 75,
            "level": 1,
            "amount": null,
            "contracts": null,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": null
        },
        {
            "row_title": "Tenencias significativas",
            "row_order": 76,
            "level": 4,
            "amount": -8904835.73,
            "contracts": 1.0,
            "ponderation_CRR2": 250.0,
            "ponderation_CRR3": 100.0,
            "reclassification": 17,
            "impact": 13357253.595000003
        },
        {
            "row_title": "TOTAL",
            "row_order": 77,
            "level": 5,
            "amount": -5419682912.277808,
            "contracts": 338465.0,
            "ponderation_CRR2": null,
            "ponderation_CRR3": null,
            "reclassification": null,
            "impact": -2426247.2745694146
        }
    ]
}

const getRowTitleStyle = (level) => {
    switch (level) {
        case 1: return { fontSize: "13px", fontWeight: "bold" };
        case 2: return { fontSize: "13px", fontWeight: 500 };
        case 3: return { fontSize: "13px", textDecoration: "underline" };
        case 4: return { fontSize: "13px", marginLeft: "200px" };
        case 5: return { fontSize: "13px", fontWeight: "bold" };
        default: return {};
    }
};

const getRowIndent = (level) => {
    switch (level) {
        case 1: return 0;
        case 2: return 20;
        case 3: return 40;
        case 4: return 60;
        case 5: return 0;
        default: return 0;
    }
};

const getCellAlignment = (value) => (typeof value === 'number' ? 'right' : 'left');

export default function ExpositionsTable() {
    const formatNumber = useNumberFormatter();

    const orderedColumns = Object.entries(expositions_data.columns)
        .sort((a, b) => a[1].column_order - b[1].column_order)
        .map(([key, value]) => ({ key, name: value.name, type: value.type }));

    const sortedRows = [...expositions_data.current_values].sort((a, b) => a.row_order - b.row_order);

    return (
        <TableContainer component={Paper}>
            <Table>
                {/* Encabezado de la tabla */}
                <TableHead>
                    <InformesTableRow>
                        {orderedColumns.map(({ key, name }) => (
                            <InformesTableCell 
                                key={key} 
                                style={{ 
                                    width: key === "row_title" ? '40%' : '8%', 
                                    textAlign: "left",  
                                    verticalAlign: "top"
                                }}
                            >
                                {name}
                            </InformesTableCell>
                        ))}
                    </InformesTableRow>
                </TableHead>

                {/* Cuerpo de la tabla */}
                <TableBody>
                    {sortedRows.map((row, index) =>
                        row.level === 5 ? (
                            <StyledTotalRow key={index}>
                                {orderedColumns.map(({ key, type }) => {
                                    let value = row[key];

                                    // Regla para valores null
                                    if (value === null) {
                                        value = row.level === 4 ? "-" : "";
                                    }

                                    // Aplicar formato si es float o integer
                                    if (type === "float" && value !== "" && value !== "-") {
                                        value = formatNumber(value);
                                    } else if (type === "integer" && value !== "" && value !== "-") {
                                        value = Math.round(value);
                                    }

                                    return (
                                        <InformesTableCell
                                            key={key}
                                            style={{
                                                ...(key === "row_title" ? getRowTitleStyle(row.level) : {}),
                                                textAlign: getCellAlignment(row[key])
                                            }}
                                        >
                                            {value}
                                        </InformesTableCell>
                                    );
                                })}
                            </StyledTotalRow>
                        ) : (
                            <InformesTableRow key={index}>
                                {orderedColumns.map(({ key, type }) => {
                                    let value = row[key];

                                    // Regla para valores null
                                    if (value === null) {
                                        value = row.level === 4 ? "-" : "";
                                    }

                                    // Aplicar formato si es float o integer
                                    if (type === "float" && value !== "" && value !== "-") {
                                        value = formatNumber(value);
                                    } else if (type === "integer" && value !== "" && value !== "-") {
                                        value = Math.round(value);
                                    }

                                    return (
                                        <InformesTableCell 
                                            key={key}
                                            style={{
                                                ...(key === "row_title" ? getRowTitleStyle(row.level) : {}),
                                                paddingLeft: key === "row_title" ? `${getRowIndent(row.level)}px` : "inherit",
                                                textAlign: getCellAlignment(row[key])
                                            }}
                                        >
                                            {value}
                                        </InformesTableCell>
                                    );
                                })}
                            </InformesTableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};