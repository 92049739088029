import React from 'react';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import { Download, Cancel } from '@mui/icons-material';
import { StyledTableCell, StyledTableRow } from '../../../components/CustomStyles';
import { GREY } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import { loadFilesService } from '../../../services/loadFilesService';


const Row = (props) => {
    const { row } = props;
    const { getLoadFile } = loadFilesService();

    const downloadFile = () => {
        const fileDownloadName = row.data_type + '_' + row.file_id + '.zip';
        const [year, month] = row.data_date.split("-");
        getLoadFile({ 
            data_type: row.data_type, 
            file_id: row.file_id, 
            month: month, 
            year: year, 
            fileDownloadName: fileDownloadName,
            fileType: 'csv'
        });
    };

    if (row.error) {
        return (
            <TableRow sx={{ fontWeight: 700, backgroundColor: '#ffe6e6', height: '30px' }} >
                <StyledTableCell>{row.data_type}</StyledTableCell>
                <StyledTableCell colSpan={3}>{row.error}</StyledTableCell>
                <StyledTableCell>
                    <IconButton size={'small'} >
                        <Cancel fontSize='small' style={{ color: 'red' }}/>
                    </IconButton>
                </StyledTableCell>
            </TableRow>
        );
    }
    else {
        return (
            <TableRow sx={{ fontWeight: 700, backgroundColor: GREY, height: '30px' }} >
                <StyledTableCell>{row.data_type}</StyledTableCell>
                <StyledTableCell>{row.data_set}</StyledTableCell>
                <StyledTableCell>{row.load_date}</StyledTableCell>
                <StyledTableCell>{row.user}</StyledTableCell>
                <StyledTableCell>
                    <IconButton size={'small'} onClick={ downloadFile } >
                        <Download fontSize='small' />
                    </IconButton>
                </StyledTableCell>
            </TableRow>
        );
    }
}


export default function FilesSummaryTable(props) {
    const { inputFiles } = props;
    const { isBelowLgScreen } = useResponsive();
    
    return (
        <Box
        component={Paper}
        sx={{ boxShadow: 'none' }}
        >
            <TableContainer 
                component={Paper}
                sx={{ overflowX: isBelowLgScreen ? 'auto' : 'hidden'}}
            >
                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 8px', minWidth: '900px'}}>
                    <TableHead>
                        <StyledTableRow>
                        <StyledTableCell width={"15%"}>Tipo de fichero</StyledTableCell>
                        <StyledTableCell width={"25%"}>Conjunto de datos</StyledTableCell>
                        <StyledTableCell width={"11%"}>Fecha de importación</StyledTableCell>
                        <StyledTableCell width={"8%"}>Usuario importación</StyledTableCell>
                        <StyledTableCell width={"1%"} />
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {inputFiles && inputFiles.map((row, index) => (
                            <Row
                                key={index}
                                index={index}
                                row={row}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box >
    );
}