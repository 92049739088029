// BarChart.js
import React from 'react';
import Chart from 'react-apexcharts';
import { COLOR_PALETTE } from '../../../theme';

const BarChart = ({
    categories = [""],
    series = [],
    title = '',
    xAxisTitle = '',
    yAxisTitle = '',
    width = '100%',
    height = '400px',
    stacked = false,
    orientation = 'vertical',
}) => {
    const options = {
        chart: {
            type: 'bar',
            stacked: stacked,
            stackType: '100%',
            toolbar: {
                show: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: orientation === 'horizontal',
                barHeight: '80%'
            }
        },
        xaxis: {
            categories: categories,
            title: {
                text: xAxisTitle,
            },
            labels: {
                formatter: function (value) {
                    return `${value}%`; // Format X-axis as percentage
                },
                style: {
                    fontWeight: 'bold', // Esto pone en negrita los porcentajes en el eje X
                },
            }
        },
        yaxis: {
            title: {
                text: yAxisTitle,
            },
            labels: {
                style: {
                    fontWeight: 'bold', // Esto pone en negrita los porcentajes en el eje Y
                },
            },
        },
        title: {
            text: title,
            align: 'center'
        },
        legend: {
            position: 'bottom'
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return `${val.toFixed(2)}%`;
            },
            style: {
                colors: ['#4D4D4D'], // Cambia el color de los porcentajes a negro
                fontSize: '12px',   // Ajusta el tamaño de la fuente si es necesario
                fontWeight: 'bold', // Opcional: hace el texto más visible
            }
        },
        tooltip: {
            followCursor: true, // Muestra el tooltip en la posición del cursor
            shared: false, // Evita la agrupación de tooltips
            intersect: true, // Muestra el tooltip solo para la barra sobre la que estás
            y: {
                formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                    // Obtener el total de la barra actual sumando los valores de la categoría
                    const total = w.config.series.reduce((acc, series) => acc + series.data[dataPointIndex], 0);
                    
                    // Calcular el porcentaje de la barra apilada actual
                    const percentage = ((val / total) * 100).toFixed(2);
    
                    // Mostrar el valor en euros y el porcentaje
                    return `${val.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })} (${percentage}%)`;
                }
            },
        },
        colors: COLOR_PALETTE
    };

    return (
        <Chart
            options={options}
            series={series.length ? series : [{ name: '', data: [0] }]} // Ensure series has a valid structure
            type="bar"
            width={width}
            height={height}
        />
    );
};

export default BarChart;
