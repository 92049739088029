import React, { useState, useEffect, useRef } from 'react';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TextField, IconButton, Collapse, Stack } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Clear as ClearIcon } from '@mui/icons-material';
import { InformesTableCell, InformesTableRow } from '../../../components/CustomStyles';
import { useResponsive } from '../../../hooks/useResponsive';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import { useErrors } from '../../../hooks/useErrors';
import InLineError from '../../../components/InLineError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { styled } from '@mui/material/styles';
import { GREY } from '../../../theme';

const StyledTotalRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        backgroundColor: GREY,
        fontWeight: 'bold',
    },
}));

const canFilter = ["Epigrafe", "id_categoria_exposicion", "category_name"];

export default function SummaryTable({ executionYear, executionMonth, processId, initialData, isFirstRender }) {
    const { isBelowXlScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();
    const { getCreditRiskReportSummary } = reportsCreditRiskService();
    const formatNumber = useNumberFormatter();
    const [parameters, setParameters] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [totals, setTotals] = useState({});
    const [filters, setFilters] = useState({});
    const [expandedFilters, setExpandedFilters] = useState({});
    const columnRefs = useRef({}); // Referencias para medir anchos iniciales
    const [columnWidths, setColumnWidths] = useState({}); // Anchos iniciales

    useEffect(() => {
        if (processId && !isFirstRender.current) {
            fetchReportData();
        }
    }, [processId]);

    useEffect(() => {
        if (initialData && Object.keys(initialData).length > 0) {
            setReportStates(initialData);
        }
    }, [initialData]);

    useEffect(() => {
        // Medir el ancho inicial de cada columna después de renderizar
        const widths = {};
        for (const key in columnRefs.current) {
            if (columnRefs.current[key]) {
                widths[key] = columnRefs.current[key].offsetWidth;
            }
        }
        setColumnWidths(widths);
    }, [parameters, reportData]);

    const fetchReportData = async () => {
        const response = await getCreditRiskReportSummary({ year: executionYear, month: executionMonth, process_id: processId });
        setReportStates(response);
    };

    const setReportStates = (data) => {
        if (data.current_values) {
            const { parameters, current_values } = data;

            const sortedParams = Object.keys(parameters).sort((a, b) => parameters[a].column_order - parameters[b].column_order);

            const totalsByCategory = calculateTotals(current_values);

            const sortedData = current_values.sort((a, b) => {
                if (a.Epigrafe === b.Epigrafe) {
                    return a.id_categoria_exposicion.localeCompare(b.id_categoria_exposicion);
                }
                return a.Epigrafe.localeCompare(b.Epigrafe);
            });

            setParameters(sortedParams.map(param => ({ key: param, name: parameters[param].name })));
            setReportData(sortedData);
            setTotals(totalsByCategory);
            setFilters(Object.fromEntries(sortedParams.map(param => [param, ''])));
            setExpandedFilters(Object.fromEntries(sortedParams.map(param => [param, false])));
        }
    };

    const calculateTotals = (data) => {
        const categories = [...new Set(data.map(item => item.Epigrafe))];
        const totals = {};
    
        categories.forEach(category => {
            const filteredData = data.filter(item => item.Epigrafe === category);
            let countPonderacion = 0; // Contador para calcular la media de 'pc_ponderacion'
    
            totals[category] = filteredData.reduce((acc, curr) => {
                Object.keys(curr).forEach(key => {
                    if (typeof curr[key] === 'number') {
                        if (key === 'pc_ponderacion') {
                            acc[key] = (acc[key] || 0) + curr[key]; // Sumar para calcular la media después
                            countPonderacion++; // Contar la cantidad de valores
                        } else {
                            acc[key] = (acc[key] || 0) + curr[key]; // Mantener la suma para otras columnas
                        }
                    }
                });
                return acc;
            }, {});
    
            // Convertir 'pc_ponderacion' en la media en lugar de la suma
            if (countPonderacion > 0) {
                totals[category]['pc_ponderacion'] = totals[category]['pc_ponderacion'] / countPonderacion;
            }
    
            totals[category]['Epigrafe'] = `${category} TOTAL`;
        });
    
        return totals;
    };
    

    const toggleFilter = (key) => {
        const isCurrentlyExpanded = expandedFilters[key];
        setExpandedFilters({ ...expandedFilters, [key]: !isCurrentlyExpanded });

        if (isCurrentlyExpanded) {
            setFilters({ ...filters, [key]: '' });
        }
    };

    const handleFilterChange = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };

    const getFilteredData = () => {
        // Filtrar filas normales
        const filteredRows = reportData.filter(row =>
            parameters.every(param =>
                canFilter.includes(param.key)
                    ? String(row[param.key] || '').toLowerCase().includes(filters[param.key].toLowerCase())
                    : true
            )
        );
    
        // Filtrar filas de totales
        const filteredTotals = Object.keys(totals).reduce((result, category) => {
            const totalRow = totals[category];
            const matchesEpigrafeFilter = String(totalRow['Epigrafe'] || '').toLowerCase().includes(filters['Epigrafe'].toLowerCase());
            
            // Agregar solo si el filtro de Epigrafe coincide
            if (matchesEpigrafeFilter) {
                result[category] = totalRow;
            }
    
            return result;
        }, {});
    
        return { filteredRows, filteredTotals };
    };
    

    const getCellAlignment = (value) => (typeof value === 'number' ? 'right' : 'left');

    if (loading.getCreditRiskReportSummary || loading.getLastExecution) {
        return <LoadingSpinner />;
    } else if (inLineErrors.getCreditRiskReportSummary) {
        return <InLineError width={"100%"} length={"100%"} errorMessage={inLineErrors.getCreditRiskReportSummary} />;
    } else {
        const filteredData = getFilteredData();

        return (
            <TableContainer component={Paper} sx={{ overflowX: isBelowXlScreen ? 'auto' : 'hidden', height: reportData.length < 30 ? 'auto' : 'calc(100vh - 100px)' }}>
                <Table stickyHeader size="small" >
                    <TableHead>
                        <InformesTableRow>
                            {parameters.map((param, index) => (
                                <InformesTableCell
                                    key={param.key}
                                    ref={(el) => (columnRefs.current[param.key] = el)} // Guardar referencia
                                    sx={{
                                        textAlign: 'left',
                                        verticalAlign: 'top',
                                        minWidth: columnWidths[param.key] ? `${columnWidths[param.key]}px` : 'auto',
                                        maxWidth: columnWidths[param.key] ? `${columnWidths[param.key]}px` : 'auto',
                                    }}
                                >
                                    <Stack direction="column" sx={{mx: '2px'}}>
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                            {param.name}
                                            {canFilter.includes(param.key) && (
                                                <IconButton size="small" onClick={() => toggleFilter(param.key)}>
                                                    {expandedFilters[param.key] ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                                                </IconButton>
                                            )}
                                        </Stack>
                                        {canFilter.includes(param.key) && (
                                            <Collapse in={expandedFilters[param.key]} timeout="auto" unmountOnExit>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    value={filters[param.key]}
                                                    onChange={(e) => handleFilterChange(param.key, e.target.value)}
                                                    placeholder={`Filtrar ${param.name}`}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton size="small" onClick={() => toggleFilter(param.key)}>
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        ),
                                                        style: { fontSize: '13px', height: '24px' },
                                                    }}
                                                    sx={{
                                                        height: '28px',
                                                        width: '100%',
                                                        '& .MuiOutlinedInput-root': {
                                                            padding: 0,
                                                        },
                                                        '& input': {
                                                            padding: '4px',
                                                        },
                                                    }}
                                                />
                                            </Collapse>
                                        )}
                                    </Stack>
                                </InformesTableCell>
                            ))}
                        </InformesTableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.filteredRows.length === 0 && Object.keys(filteredData.filteredTotals).length === 0 ? (
                            <InformesTableRow>
                                <InformesTableCell colSpan={parameters.length} sx={{ textAlign: 'center' }}>
                                    No hay datos para mostrar
                                </InformesTableCell>
                            </InformesTableRow>
                        ) : (
                            Object.keys(filteredData.filteredTotals).map((category, categoryIndex) => (
                                <React.Fragment key={categoryIndex}>
                                    {filteredData.filteredRows
                                        .filter(row => row.Epigrafe === category)
                                        .map((row, rowIndex) => (
                                            <InformesTableRow key={rowIndex}>
                                                {parameters.map((param, idx) => (
                                                    <InformesTableCell
                                                        key={idx}
                                                        sx={{ textAlign: getCellAlignment(row[param.key]) }}
                                                    >
                                                        {typeof row[param.key] === 'number'
                                                            ? formatNumber(row[param.key])
                                                            : row[param.key]}
                                                    </InformesTableCell>
                                                ))}
                                            </InformesTableRow>
                                        ))}
                                    {filteredData.filteredTotals[category] && (
                                        <StyledTotalRow>
                                            {parameters.map((param, idx) => (
                                                <InformesTableCell
                                                    key={idx}
                                                    sx={{ textAlign: getCellAlignment(filteredData.filteredTotals[category][param.key]) }}
                                                >
                                                    {typeof filteredData.filteredTotals[category][param.key] === 'number'
                                                        ? formatNumber(filteredData.filteredTotals[category][param.key])
                                                        : filteredData.filteredTotals[category][param.key] || ''}
                                                </InformesTableCell>
                                            ))}
                                        </StyledTotalRow>
                                    )}
                                </React.Fragment>
                            ))
                        )}
                    </TableBody>

                </Table>
            </TableContainer>
        );
    }
}
