import { React, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Collapse, Grid, Typography, Dialog, DialogActions, Menu, MenuItem, Divider, Stack,
         DialogContent, DialogTitle, Button, Tabs, Checkbox, IconButton, TextField } from '@mui/material';
import { AntTab } from '../../../components/CustomStyles';
import { FileUpload, ArrowCircleRight, Cancel, CheckCircle, CloudUpload, Menu as MenuIcon, HighlightOff } from '@mui/icons-material/';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StyledDatePicker } from '../../../components/CustomStyles';
import { StyledButton, StyledBox } from '../../../components/CustomStyles';
import EntryFilesTable from '../tables/EntryFilesTable';
import CustomSelect from '../../../components/CustomSelect';
import { SELECT_TEXT, BLUE, BLACK, TITLE_TEXT, DARK_GREY, CONTENT_BOX, SUBTITLE_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import { parametrizationService } from '../../../services/parametrizationService';
import { loadFilesService } from '../../../services/loadFilesService';



const changeMonthStructure = (month) => {
    var changedMonth = String(month + 1);
    if (changedMonth.length === 1) {
        changedMonth = '0' + changedMonth;
    }
    return changedMonth;
}


const UploadDialog = (props) => {
    const { openUploadDialog, toggleUploadDialog, fileType, fetchLoadData, initialDate } = props;
    const { postLoadFile } = loadFilesService();
    const [fileDate, setFileDate] = useState(initialDate);
    const [file, setFile] = useState(null);
    const [fileUploadDescription, setFileUploadDescription] = useState('');
    const [validateFile, setValidateFile] = useState(false);

    useEffect(() => {
        setFileDate(initialDate)
    }, [initialDate])

    const handleFileUploadDescriptionChange = (event) => {
        setFileUploadDescription(event.target.value);
    };

    const onDrop = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
    };

    const removeFile = () => {
        URL.revokeObjectURL(file.preview); // Liberar memoria
        setFile(null); // Elimina el archivo del estado
    };

    const submitFileUpload = async () => {
        if (file) {
            const formData = new FormData();
            formData.append("uploaded_file", file);
            await postLoadFile({
                formData: formData, 
                month: changeMonthStructure(fileDate.$M), 
                year: fileDate.$y, 
                dataSet: fileUploadDescription, 
                fileType: fileType, 
                validations: validateFile ? 'True' : 'False'
            });
        }
        setFileDate(initialDate)
        removeFile();
        toggleUploadDialog();
        fetchLoadData(fileType)
    };

    const handleOnChangeFileDate = (value) => {
        setFileDate(value)
    };

    const handleChangeValidateFile = () => {
        setValidateFile(!validateFile);
    }
    
    return (
        <Dialog
            open={openUploadDialog}
            onClose={toggleUploadDialog}
            maxWidth='lg'
            fullWidth
        >
            <DialogTitle
                color={BLUE}
                sx={TITLE_TEXT}
            >
                Subir fichero de entrada de {fileType}
            </DialogTitle>
            <DialogContent >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: '10px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <StyledDatePicker 
                            sx={{ width: '40%' }}
                            views={['year', 'month']} 
                            value={fileDate} 
                            onChange={(value) => handleOnChangeFileDate(value)}
                            slotProps={{
                                actionBar: {
                                    actions: ['clear']
                                }
                            }}
                            closeOnSelect={true}
                        />
                    </LocalizationProvider>
                </Box>
                <TextField
                    label="Descripción"
                    multiline
                    rows={2} // Adjust the number of rows as needed
                    variant="outlined"
                    value={fileUploadDescription}
                    onChange={handleFileUploadDescriptionChange}
                    fullWidth 
                    sx={{ mt: '4px' }}
                />
                {file ? 
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Typography >
                            Archivo seleccionado:
                        </Typography>
                        <Typography sx={{color: BLUE, mx: '6px'}} >
                            {file.name}
                        </Typography>
                        <Typography >
                            ({Math.round(file.size / 1024)} KB)
                        </Typography>
                        <IconButton onClick={removeFile}>
                            <HighlightOff style={{ color: DARK_GREY }}/>
                        </IconButton>
                    </Box>
                :
                <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <StyledBox {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography>Arrastre y suelte el fichero de carga, o haga clic para seleccionarlo</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CloudUpload sx={{ fontSize: 100 }} />
                            </Box>
                        </StyledBox>
                    )}
                </Dropzone >
                }
                <Box display={'flex'} alignItems="center" >
                    <Checkbox
                        checked={validateFile}
                        name="validateFile"
                        sx={{ height: '25px', width: '25px', 'ariaLabel': 'controlled' }}
                        onClick={handleChangeValidateFile}
                    />
                    <Typography sx={SELECT_TEXT}>
                        Validar fichero
                    </Typography>
                </Box>
                
            </DialogContent>
            <DialogActions
                sx={{ mb: '20px', justifyContent: 'center' }}
            >
                <Button
                    variant='contained'
                    sx={{ fontSize: '13px', textTransform: 'none' }}
                    endIcon={<Cancel />}
                    onClick={toggleUploadDialog}
                >
                    Cancelar
                </Button>
                <Button
                    variant='contained'
                    sx={{ ml: '20px', fontSize: '13px', textTransform: 'none' }}
                    endIcon={<CheckCircle />}
                    onClick={() => {submitFileUpload()}}
                    disabled={!(file && fileDate)}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog >
    );
}



export default function StepOne(props) {
    const { activeStep, handleOnClickNext, selectFilters, setSelectFilters, checks, setChecks,
            selectedEntryFiles, setSelectedEntryFiles } = props;
    const { getParametrization } = parametrizationService();
    const { getLoadFiles } = loadFilesService();
    const { isBelowXlScreen } = useResponsive();
    const [processesNamesAndFileTypes, setProcessesNamesAndFileTypes] = useState([]);
    const [filteredFileTypes, setFilteredFileTypes] = useState([]);
    const [entryFiles, setEntryFiles] = useState([]);
    const [paginationData, setPaginationData] = useState({start: 0, limit: 5, count: 0, page: 1});
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [selectedFileTypeTab, setSelectedFileTypeTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        fetchParametrization();
    }, []);

    useEffect(() => {
        if (selectFilters.process) {
            const auxFilteredFileTypes = filterFileTypes();
            setFilteredFileTypes(auxFilteredFileTypes);
            if (selectedFileTypeTab >= auxFilteredFileTypes.length) {
                setSelectedFileTypeTab(0);
            }
        }
    }, [selectFilters.process, JSON.stringify({ ...checks, lastLoad: undefined })]);

    useEffect(() => {
        if (showEntryFilesTable()) {
            const auxFilteredFileTypes = filterFileTypes();
            fetchLoadData(auxFilteredFileTypes[selectedFileTypeTab]);
        }
    }, [selectFilters, checks.lastLoad, selectedFileTypeTab]);


    const fetchParametrization = async () => {
        const data = await getParametrization({parameterCode: "processes"});
        if (data) {
            setProcessesNamesAndFileTypes(data.processes);
        }
    }

    const fetchLoadData = async (fileType) => {
        const data = await getLoadFiles({
            data_type: fileType, 
            month: changeMonthStructure(selectFilters.fullDataDate.$M), 
            year: selectFilters.fullDataDate.$y, 
            limit: paginationData.limit, 
            start: paginationData.start
        })
        if (data && data.results.length > 0 && !selectedEntryFiles.hasOwnProperty(fileType)){
            const initialEntryfile = data.results[0];
            const [year, month] = initialEntryfile.data_date.split("-");
            setSelectedEntryFiles({
                ...selectedEntryFiles, 
                    [fileType]: {
                    month: month,
                    year: year,
                    fileID: initialEntryfile.file_id
                }
            })
        } 
        setEntryFiles(data.results);
        setPaginationData({start: data.start, limit: data.limit, count: data.count, page: 1});
    }

    // Filtramos los tipos de fichero que se pueden seleccionar dependiendo de si esta en checks o no
    const filterFileTypes = () => {
        const filteredFiles = processesNamesAndFileTypes.find(process => process.name === selectFilters.process).fileTypes.filter(fileType => {
            // Si el fileType no aparece en checks, lo incluimos
            if (!(fileType in checks)) {
                return true;
            }
            // Si aparece en checks y es true, lo incluimos
            return checks[fileType] === true;
        });
        return filteredFiles
    }

    // Actualizamos los checks al cambiar de proceso
    const updateChecks = (newSelectedProcess) => {
        const auxChecks = {...checks};
        if (selectFilters.process.length > 0) {
            const prevOptionalFileTypes = processesNamesAndFileTypes.find(process => process.name === selectFilters.process).optionalFileTypes
            for (const prevOptionalFileType of prevOptionalFileTypes) {
                delete auxChecks[prevOptionalFileType];
            }
        }
        const newOptionalFileTypes = processesNamesAndFileTypes.find(process => process.name === newSelectedProcess).optionalFileTypes
        for (const newOptionalFileType of newOptionalFileTypes) {
            auxChecks[newOptionalFileType] = false;
        }
        setChecks(auxChecks);
    }

    // Cambiar el proceso seleccionado, actualizar los checks, establecer el tab al primero y limpiar los ficheros seleccionados
    const handleOnChangeProcess = (event) => {
        const newSelectedProcess = event.target.value
        updateChecks(newSelectedProcess);
        const possibleNormativeVersions = processesNamesAndFileTypes.find(process => process.name === newSelectedProcess).normativeVersions
        const keepPrevNormativeVersion = possibleNormativeVersions.find(normativeVersion => normativeVersion === selectFilters.ebaNormative)
        const auxNormativeVersion = keepPrevNormativeVersion ? selectFilters.ebaNormative : (possibleNormativeVersions.length === 1 ? possibleNormativeVersions[0] : '')
        setSelectFilters({...selectFilters, process: newSelectedProcess, ebaNormative: auxNormativeVersion});
        setSelectedFileTypeTab(0);
        setSelectedEntryFiles({});
    };

    // Cambiar la versión EBA y Normativa seleccionada, limpiar los ficheros seleccionados
    const handleOnChangeEBANormative = (event) => {
        setSelectFilters({...selectFilters, ebaNormative: event.target.value});
        setSelectedEntryFiles({});
    };

    // Cambiar la fecha de datos seleccionada, limpiar los ficheros seleccionados
    const handleOnChangeDataDate = (value) => {
        setSelectFilters({...selectFilters, fullDataDate: value})
        setSelectedEntryFiles({});
    };

    // Al hacer click en una fila de la tabla de ficheros de entrada, actualizamos los ficheros seleccionados
    const handleOnClickRow = (row) => {
        const [year, month] = row.data_date.split("-");
        setSelectedEntryFiles({
            ...selectedEntryFiles,
            [row.data_type]: {
                month: month, 
                year: year,
                fileID: row.file_id
            }
        })
    }

    // Comprobamos si se han seleccionado todos los campos necesarios para pasar al siguiente paso
    const isStepOneNotDone = () => {
        return selectFilters.process.length < 1
        || selectFilters.ebaNormative.length < 1
        || !selectFilters.fullDataDate
        || (Object.keys(selectedEntryFiles).length === 0
        && !checks.lastLoad);
    };

    // Comprobamos si se deben mostrar los ficheros de entrada
    const showEntryFilesTable = () => {
        return selectFilters.process.length > 0 
            && selectFilters.ebaNormative.length > 0 
            && selectFilters.fullDataDate 
            && !checks.lastLoad;
    }

    // Mostrar/ocultar el dialogo de subida de ficheros
    const toggleUploadDialog = () => {
        setOpenUploadDialog(!openUploadDialog);
    };

    // Cambiar el tab de tipo de fichero seleccionado
    const handleChangeTab = (_event, value) => {
        setSelectedFileTypeTab(value);
        setEntryFiles([]);
    };

    // Seleccionar / deseleccionar los checks
    const handleChangeChecked = (event) => {
        const checkName = event.target.name;
        if (checkName === "lastLoad") {
            setSelectedEntryFiles({})
        }
        setChecks({...checks, [checkName]: event.target.checked});
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setSelectedFileTypeTab(index);
        setEntryFiles([]);
        handleMenuClose();
    };


    return (
        <Collapse in={activeStep === 0}>
            <Box sx={{...CONTENT_BOX, mt: '0px', pb: '25px' }}>
            <Typography
                    color={BLUE}
                    sx={SUBTITLE_TEXT}
            >
                Selección del tipo de proceso
            </Typography>
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: '0px', mb: '15px' }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <CustomSelect
                            title={'Proceso'}
                            selectItems={processesNamesAndFileTypes ? processesNamesAndFileTypes.map(process => process.name) : []}
                            value={selectFilters.process}
                            onChange={handleOnChangeProcess}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <CustomSelect
                            title={'Versión EBA y Normativa'}
                            selectItems={(processesNamesAndFileTypes && selectFilters.process) ? processesNamesAndFileTypes.find(process => process.name === selectFilters.process).normativeVersions : []}
                            value={selectFilters.ebaNormative}
                            onChange={handleOnChangeEBANormative}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                    >
                        <Typography sx={{ ...SELECT_TEXT, mb: '5px' }}>
                            Fecha de datos
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledDatePicker 
                                sx={{ width: '100%' }}
                                views={['year', 'month']} 
                                value={selectFilters.fullDataDate} 
                                onChange={(value) => handleOnChangeDataDate(value)}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear']
                                    }
                                }}
                                closeOnSelect={true}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Stack direction="row" spacing={4}>
                    <Box display={'flex'} alignItems="center" >
                        <Checkbox
                            checked={checks.lastLoad}
                            name="lastLoad"
                            sx={{ height: '25px', width: '25px', 'ariaLabel': 'controlled' }}
                            onClick={handleChangeChecked}
                        />
                        <Typography sx={SELECT_TEXT}>
                            Utilizar últimos ficheros de entrada disponibles
                        </Typography>
                    </Box>
                    {Object.entries(checks).filter(([key]) => key !== 'lastLoad').map(([key, value]) => (
                        <Box key={key} display={'flex'} alignItems="center">
                            <Checkbox
                                checked={value}
                                name={key}
                                sx={{ height: '25px', width: '25px', 'ariaLabel': 'controlled' }}
                                onClick={handleChangeChecked}
                            />
                            <Typography sx={SELECT_TEXT}>
                                Utilizar fichero de {key}
                            </Typography>
                        </Box>
                    ))}
                </Stack>

                
                
                {!showEntryFilesTable() &&
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <StyledButton
                            onClick={() => {handleOnClickNext();}}
                            variant='contained'
                            endIcon={<ArrowCircleRight />}
                            disabled={isStepOneNotDone()}
                        >
                            Siguiente
                        </StyledButton>
                    </Box>
                }
            </Box>
            
            
            <Collapse in={showEntryFilesTable()}>
                <Box sx={{...CONTENT_BOX, mt: '0px', pb: '25px' }}> 
                    <Typography
                        color={BLUE}
                        sx={SUBTITLE_TEXT}
                    >
                        Selección de ficheros de entrada
                    </Typography>
                    {isBelowXlScreen ? (
                        <Box display={'flex'}>
                            <Typography
                                    sx={{...TITLE_TEXT, mt: '9px'}}
                                >
                                    {filteredFileTypes[selectedFileTypeTab]}
                            </Typography>
                            <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {filteredFileTypes.map((fileType, index) => 
                                    <MenuItem 
                                        key={index}
                                        onClick={() => handleMenuItemClick(index)}
                                        sx={{ color: selectedFileTypeTab === index ? BLUE : BLACK }}
                                    >
                                        {fileType}
                                    </MenuItem>
                                )}
                            </Menu>
                        </Box>
                    ) : (
                        <Tabs 
                            sx={{mb: '0px', minHeight: "38px", height: "38px", mt: '15px'}}
                            value={selectedFileTypeTab}
                            onChange={handleChangeTab}
                            variant="scrollable"    // Habilita el scroll si se excede el espacio visible
                            scrollButtons="auto"
                        >
                            {filteredFileTypes.map((fileType, index) => 
                                <AntTab
                                    key={index}
                                    tab={index}
                                    label={fileType}
                                />
                            )}
                        </Tabs>
                    )}
                    <Box>
                        <Grid container alignItems={'center'} sx={{mt: '10px'}}>
                            <Grid item xs={6} md={8}>
                            </Grid>
                            <Grid item xs={6} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant='contained'
                                    sx={{ fontSize: '13px', textTransform: 'none' }}
                                    endIcon={<FileUpload />}
                                    onClick={toggleUploadDialog}
                                >
                                    Subir Archivo
                                </Button>
                            </Grid>
                        </Grid>
                        <UploadDialog
                            openUploadDialog={openUploadDialog}
                            toggleUploadDialog={toggleUploadDialog}
                            fileType={filteredFileTypes[selectedFileTypeTab]}
                            fetchLoadData={fetchLoadData}
                            initialDate={selectFilters.fullDataDate}
                        />
                        <EntryFilesTable 
                            selectedEntryFile={selectedEntryFiles[filteredFileTypes[selectedFileTypeTab]]}
                            handleOnClickRow={handleOnClickRow} 
                            entryFiles={entryFiles} 
                            setEntryFiles={setEntryFiles}
                            setPaginationData={setPaginationData} 
                            paginationData={paginationData} 
                            dataType={filteredFileTypes[selectedFileTypeTab]} 
                            selectFilters={selectFilters}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: '26px', mb: '15px' }}>
                        <StyledButton
                            onClick={() => {handleOnClickNext();}}
                            variant='contained'
                            endIcon={<ArrowCircleRight />}
                            disabled={isStepOneNotDone()}
                        >
                            Siguiente
                        </StyledButton>
                    </Box>
                </Box>
            </Collapse>
        </Collapse >
    );
}