import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { InformesTableCell, InformesTableRow } from "../../../components/CustomStyles";
import { margin, styled } from "@mui/system";
import { GREY } from "../../../theme";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const StyledTotalRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        backgroundColor: GREY,
        fontWeight: 'bold',
    },
}));

const expositions_data = {
    "columns": {
        "row_title": {
            "name": "",
            "column_order": 1
        },
        "date1": {
            "name": "01/09/2024",
            "column_order": 2
        },
        "date2": {
            "name": "01/06/2024",
            "column_order": 3
        },
        "date3": {
            "name": "01/03/2024",
            "column_order": 4
        },
        "date4": {
            "name": "01/12/2023",
            "column_order": 5
        },
        "date5": {
            "name": "01/09/2023",
            "column_order": 6
        }
    },
    "current_values": [
        {
            "row_title": "Fondos propios disponibles (importes)",
            "row_order": 1,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null,
            "date5": null
        },
        {
            "row_title": "Capital de nivel 1 ordinario",
            "row_order": 2,
            "level": 2,
            "date1": 2514092,
            "date2": 2479011,
            "date3": 2421374,
            "date4": 2398322,
            "date5": 2351134
        },
        {
            "row_title": "Capital de nivel 1",
            "row_order": 3,
            "level": 2,
            "date1": 2869993,
            "date2": 2829011,
            "date3": 2771374,
            "date4": 2748322,
            "date5": 2701134
        },
        {
            "row_title": "Capital total",
            "row_order": 4,
            "level": 2,
            "date1": 3359508,
            "date2": 3329011,
            "date3": 3271374,
            "date4": 3248322,
            "date5": 3201134
        },
        {
            "row_title": "Importes de las exposiciones ponderadas por riesgo",
            "row_order": 5,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null,
            "date5": null
         },
        {
            "row_title": "Importe total de la exposición al riesgo",
            "row_order": 6,
            "level": 2,
            "date1": 18809436,
            "date2": 18712926,
            "date3": 18391397,
            "date4": 18609328,
            "date5": 17997827
        },
        {
            "row_title": "Ratios de capital (en porcentaje del importe de la exposición ponderada por riesgo)",
            "row_order": 7,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null,
            "date5": null
        },
        {
            "row_title": "Ratio de capital de nivel 1 ordinario (%)",
            "row_order": 8,
            "level": 2,
            "date1": "13,17%",
            "date2": "13,25%",
            "date3": "13,17%",
            "date4": "12,89%",
            "date5": "13,06%"
        },
        {
            "row_title": "Ratio de capital de nivel 1 (%)",
            "row_order": 9,
            "level": 2,
            "date1": "15,26%",
            "date2": "15,12%",
            "date3": "15,07%",
            "date4": "14,77%",
            "date5": "15,01%"
        },
        {
            "row_title": "Ratio de capital total (%)",
            "row_order": 10,
            "level": 2,
            "date1": "17,86%",
            "date2": "17,79%",
            "date3": "17,79%",
            "date4": "17,46%",
            "date5": "17,79%"
        },
        {
            "row_title": "Requisitos de fondos propios adicionales para hacer frente a riesgos distintos del riesgo de apalancamiento excesivo (en porcentaje del importe de la exposición ponderada por riesgo)",
            "row_order": 11,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null,
            "date5": null
        },
        {
            "row_title": "Requisitos de fondos propios adicionales para hacer frente a riesgos distintos del riesgo de apalancamiento excesivo (%)",
            "row_order": 12,
            "level": 2,
            "date1": "2%",
            "date2": "2%",
            "date3": "2%",
            "date4": "2,15%",
            "date5": "2,2%"
        },
        {
            "row_title": "De los cuales: estarán compuestos por capital de nivel 1 ordinario (puntos porcentuales)",
            "row_order": 13,
            "level": 3,
            "date1": "1,125%",
            "date2": "1,125%",
            "date3": "1,13%",
            "date4": "1,21%",
            "date5": "1,21%"
        },
        {
            "row_title": "De los cuales: estarán compuestos por capital de nivel 1 (puntos porcentuales)",
            "row_order": 14,
            "level": 3,
            "date1": "1,5%",
            "date2": "1,5%",
            "date3": "1,5%",
            "date4": "1,61%",
            "date5": "1,61%"
        },
        {
            "row_title": "Total de los requisitos de fondos propios del PRES (%)",
            "row_order": 15,
            "level": 2,
            "date1": "10%",
            "date2": "10%",
            "date3": "10%",
            "date4": "10,15%",
            "date5": "10,15%"
        },
        {
            "row_title": "Colchón combinado y requisito global de capital (en porcentaje del importe de la exposición ponderada por riesgo)",
            "row_order": 16,
            "level": 2,
            "date1": "-",
            "date2": "-",
            "date3": "-",
            "date4": "-",
            "date5": "-"
        },
        {
            "row_title": "Colchón de conservación de capital (%)",
            "row_order": 17,
            "level": 2,
            "date1": "2,5%",
            "date2": "2,5%",
            "date3": "2,5%",
            "date4": "2,5%",
            "date5": "2,5%"
        },
        {
            "row_title": "Colchón de conservación debido al riesgo macroprudencial o sistémico observado en un Estado miembro (%)",
            "row_order": 18,
            "level": 2,
            "date1": "-",
            "date2": "-",
            "date3": "-",
            "date4": "-",
            "date5": "-"
        },
        {
            "row_title": "Colchón de capital anticíclico específico de la entidad (%)",
            "row_order": 19,
            "level": 2,
            "date1": "-",
            "date2": "-",
            "date3": "-",
            "date4": "-",
            "date5": "-"
        },
        {
            "row_title": "Colchón de riesgo sistémico (%)",
            "row_order": 20,
            "level": 2,
            "date1": "-",
            "date2": "-",
            "date3": "-",
            "date4": "-",
            "date5": "-"
        },
        {
            "row_title": "Colchón de entidades de importancia sistémica mundial (%)",
            "row_order": 21,
            "level": 2,
            "date1": "-",
            "date2": "-",
            "date3": "-",
            "date4": "-",
            "date5": "-"
        },
        {
            "row_title": "Colchón de otras entidades de importancia sistémica (%)",
            "row_order": 22,
            "level": 2,
            "date1": "-",
            "date2": "-",
            "date3": "-",
            "date4": "-",
            "date5": "-"
        },
        {
            "row_title": "Requisitos combinados de colchón (%)",
            "row_order": 23,
            "level": 2,
            "date1": "2,5%",
            "date2": "2,5%",
            "date3": "2,5%",
            "date4": "2,5%",
            "date5": "2,5%"
        },
        {
            "row_title": "Requisitos globales de capital (%)",
            "row_order": 24,
            "level": 2,
            "date1": "12,5%",
            "date2": "12,5%",
            "date3": "12,5%",
            "date4": "12,5%",
            "date5": "12,5%"
        },
        {
            "row_title": "Capital de nivel 1 ordinario disponible tras cumplir el total de los requisitos de fondos propios del PRES (%)",
            "row_order": 25,
            "level": 2,
            "date1": "7,74%",
            "date2": "7,62%",
            "date3": "7,54%",
            "date4": "7,15%",
            "date5": "7,35%"
        },
        {
            "row_title": "Ratio de apalancamiento",
            "row_order": 26,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null,
            "date5": null
        },
        {
            "row_title": "Medida de la exposición total",
            "row_order": 27,
            "level": 2,
            "date1": 45751598,
            "date2": 46780412,
            "date3": 46687482,
            "date4": 47431442,
            "date5": 46762077
        },
        {
            "row_title": "Ratio de apalancamiento (%)",
            "row_order": 28,
            "level": 2,
            "date1": "6,27%",
            "date2": "6,05%",
            "date3": "5,94%",
            "date4": "5,79%",
            "date5": "5,78%"
        },
        {
            "row_title": "Requisitos de fondos propios adicionales para hacer frente al riesgo de apalancamiento excesivo (en porcentaje de la medida de la exposición total)",
            "row_order": 29,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null,
            "date5": null
        },
        {
            "row_title": "Requisitos de fondos propios adicionales para hacer frente al riesgo de apalancamiento excesivo (%)",
            "row_order": 30,
            "level": 2,
            "date1": "-",
            "date2": "-",
            "date3": "-",
            "date4": "-",
            "date5": "-"
        },
        {
            "row_title": "De los cuales: estarán compuestos por capital de nivel 1 ordinario (puntos porcentuales)",
            "row_order": 31,
            "level": 3,
            "date1": "-",
            "date2": "-",
            "date3": "-",
            "date4": "-",
            "date5": "-"
        },
        {
            "row_title": "Total de los requisitos de ratio de apalancamiento del PRES (%)",
            "row_order": 32,
            "level": 2,
            "date1": "3%",
            "date2": "3%",
            "date3": "3%",
            "date4": "3%",
            "date5": "3%"
        },
        {
            "row_title": "Colchón de ratio de apalancamiento y requisito global de ratio de apalancamiento (en porcentaje de la medida de la exposición total)",
            "row_order": 33,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null,
            "date5": null
        },
        {
            "row_title": "Requisito de colchón de ratio de apalancamiento (%)",
            "row_order": 34,
            "level": 2,
            "date1": "-",
            "date2": "-",
            "date3": "-",
            "date4": "-",
            "date5": "-"
        },
        {
            "row_title": "Requisito de ratio de apalancamiento global (%)",
            "row_order": 35,
            "level": 2,
            "date1": "3%",
            "date2": "3%",
            "date3": "3%",
            "date4": "3%",
            "date5": "3%"
        },
        {
            "row_title": "Ratio de cobertura de liquidez",
            "row_order": 36,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null,
            "date5": null
        },
        {
            "row_title": "Total de activos líquidos de alta calidad (HQLA, por sus siglas en inglés) (valor ponderado, media)",
            "row_order": 37,
            "level": 2,
            "date1": 8077951,
            "date2": 7892588,
            "date3": 7607893,
            "date4": 7724632,
            "date5": 8580006
        },
        {
            "row_title": "Salidas de efectivo — Valor ponderado total",
            "row_order": 38,
            "level": 2,
            "date1": 4005497,
            "date2": 4003695,
            "date3": 3888156,
            "date4": 3880469,
            "date5": 3829311
        },
        {
            "row_title": "Entradas de efectivo — Valor ponderado total",
            "row_order": 39,
            "level": 2,
            "date1": 493538,
            "date2": 506866,
            "date3": 537686,
            "date4": 538151,
            "date5": 526820
        },
        {
            "row_title": "Total de salidas netas de efectivo (valor ajustado)",
            "row_order": 40,
            "level": 2,
            "date1": 3511958,
            "date2": 3496828,
            "date3": 3350470,
            "date4": 3342318,
            "date5": 3302491
        },
        {
            "row_title": "Ratio de cobertura de liquidez (%)",
            "row_order": 41,
            "level": 2,
            "date1": "231%",
            "date2": "227%",
            "date3": "228%",
            "date4": "233%",
            "date5": "261%"
        },
        {
            "row_title": "Ratio de financiación estable neta",
            "row_order": 42,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null,
            "date5": null
        },
        {
            "row_title": "Total de financiación estable disponible",
            "row_order": 43,
            "level": 2,
            "date1": 34966602,
            "date2": 35319266,
            "date3": 35166590,
            "date4": 34998365,
            "date5": 35337298
        },
        {
            "row_title": "Total de financiación estable total requerida",
            "row_order": 44,
            "level": 2,
            "date1": 24186937,
            "date2": 24337496,
            "date3": 24093589,
            "date4": 24766454,
            "date5": 25043092
        },
        {
            "row_title": "Ratio de financiación estable neta (%)",
            "row_order": 45,
            "level": 2,
            "date1": "145%",
            "date2": "145%",
            "date3": "146%",
            "date4": "141%",
            "date5": "141%",
        }
    ]
}


const getRowTitleStyle = (level) => {
    switch (level) {
        case 1:
            return { fontSize: "13px", fontWeight: "bold" };
        case 2:
            return { fontSize: "13px", fontWeight: 500 };
        case 3:
            return { fontSize: "13px", textDecoration: "underline" };
        case 4:
            return { fontSize: "13px", marginLeft: "200px" };
        case 5:
            return { fontSize: "13px", fontWeight: "bold" };
        default:
            return {};
    }
  };

  const getRowIndent = (level) => {
    switch (level) {
      case 1: return 0;  // Sin margen
      case 2: return 20;  // Pequeño margen
      case 3: return 40;  // Mayor margen
      case 4: return 60;  // Aumenta más
      case 5: return 0;  // Igual que level 4 (Total)
      default: return 0;
    }
  };


export default function SummaryTable() {
    const formatNumber = useNumberFormatter();
    const orderedColumns = Object.entries(expositions_data.columns)
        .sort((a, b) => a[1].column_order - b[1].column_order)
        .map(([key, value]) => ({ key, name: value.name }));

    // Ordenar las filas según `row_order`
    const sortedRows = [...expositions_data.current_values].sort((a, b) => a.row_order - b.row_order);

    return (
        <TableContainer component={Paper}>
          <Table >
            {/* Encabezado de la tabla */}
            <TableHead>
              <InformesTableRow>
                {orderedColumns.map(({ key, name }) => (
                  <InformesTableCell key={key} style={{ width: key === "row_title" ? '40%' : '8%', textAlign: "right",  verticalAlign: "top"}}>
                    {name}
                  </InformesTableCell>
                ))}
              </InformesTableRow>
            </TableHead>
    
            {/* Cuerpo de la tabla */}
            <TableBody>
              {sortedRows.map((row, index) =>
                <InformesTableRow key={index}>
                    {orderedColumns.map(({ key }) => (
                        <InformesTableCell 
                            key={key} 
                            style={{
                                ...(key === "row_title" ? getRowTitleStyle(row.level) : {}),
                                paddingLeft: key === "row_title" ? `${getRowIndent(row.level)}px` : "inherit",
                                textAlign: key === "row_title" ? "left" : "right"
                                }}
                        >
                            {typeof row[key] === 'number' ? formatNumber(row[key]) : row[key]}
                        </InformesTableCell>
                    ))}
                </InformesTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      );
};
