import React from 'react';
import { Stack, Table, TableBody, IconButton, TableHead, TableRow, Typography } from '@mui/material';
import { DownloadForOffline, FileUpload } from '@mui/icons-material';
import { StyledTableCell } from '../../components/CustomStyles';
import { executionFilesService } from '../../services/executionFilesService';


export const OutputFilesTable = (props) => {
    const { row } = props;
    const { getExecutionFile } = executionFilesService();

    const downloadOutputFile = () => {
        const fileDownloadName = 'result_' + row.process_id + '.zip';
        getExecutionFile({ 
            process_id: row.process_id, 
            month: row.execution_month, 
            year: row.execution_year, 
            fileDownloadName: fileDownloadName,
            fileType: 'csv'
        });
    };  
    
    const downloadIntermediateFile = (intermediateFile) => {
        const fileDownloadName = intermediateFile + '_' + row.process_id + '.zip';
        getExecutionFile({ 
            process_id: row.process_id, 
            month: row.execution_month, 
            year: row.execution_year, 
            result_file: intermediateFile, 
            fileDownloadName: fileDownloadName,
            fileType: 'csv'
        });
    }; 

    return (
        <>
            <Table sx={{marginBottom: '8px'}}>
                <TableHead>
                    <TableRow sx={{ height: 30 }}>
                        {row.state === 'Completado' ?
                            <>
                                <StyledTableCell style={{ fontWeight: 500 }} >
                                    Tipo de fichero    
                                </StyledTableCell>
                                <StyledTableCell />
                            </>
                            :
                            <StyledTableCell style={{ borderBottom: "none"}} colSpan={5}>
                                <Typography sx={{ fontSize: '13px', fontWeight: 400 }}>La ejecución del proceso aun no ha finalizado.</Typography>
                            </StyledTableCell>
                        }
                    </TableRow>
                </TableHead>
                {row.state === 'Completado' &&
                    <TableBody>
                        <TableRow sx={{ height: 30 }}> 
                            <StyledTableCell style={{ borderBottom: "1px solid lightgrey", backgroundColor: '#F0F8FF' }}>Salida</StyledTableCell>
                            <StyledTableCell style={{ borderBottom: "1px solid lightgrey", backgroundColor: '#F0F8FF' }} >
                                <Stack direction="row" justifyContent={'center'}>
                                    <IconButton size={'small'} onClick={ () => downloadOutputFile() }>
                                        <DownloadForOffline fontSize='small' />
                                    </IconButton>
                                    <IconButton size={'small'} >
                                        <FileUpload fontSize='small' />
                                    </IconButton>
                                </Stack>
                            </StyledTableCell>
                        </TableRow>
                        {row.intermediate_files?.map((intermediateFile) => (
                            <TableRow key={intermediateFile} sx={{ height: 30 }}>
                                <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>{intermediateFile}</StyledTableCell>
                                <StyledTableCell style={{ borderBottom: "1px solid lightgrey" }}>
                                    <Stack direction="row" justifyContent={'center'}>
                                        <IconButton size={'small'} onClick={ () => downloadIntermediateFile(intermediateFile) }>
                                            <DownloadForOffline fontSize='small' />
                                        </IconButton>
                                        <IconButton size={'small'} >
                                            <FileUpload fontSize='small' />
                                        </IconButton>
                                    </Stack>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            </Table>
        </>
    );
}