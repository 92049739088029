import React, { useState, useEffect, useRef } from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead, Stack, IconButton, Collapse, TextField } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Clear as ClearIcon } from '@mui/icons-material';
import { InformesTableCell, InformesTableRow } from '../../../components/CustomStyles';
import { useResponsive } from '../../../hooks/useResponsive';
import { reportsMarketRiskService } from '../../../services/reportsMarketRiskService';
import { useErrors } from '../../../hooks/useErrors';
import InLineError from '../../../components/InLineError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useNumberFormatter from '../../../hooks/useNumberFormatter';

const canFilter = [
    "na_entidad",
    "id_entidad_contable",
    "desc_moneda",
    "id_codigo_valor",
    "id_codigo_moneda_contrato",
];

export default function RFSpecificTable({ executionYear, executionMonth, processId, initialData, isFirstRender }) {
    const { isBelowLgScreen } = useResponsive();
    const { loading, inLineErrors } = useErrors();
    const { getMartketRiskReportRFSpecific } = reportsMarketRiskService();
    const formatNumber = useNumberFormatter();
    const [parameters, setParameters] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [filters, setFilters] = useState({});
    const [expandedFilters, setExpandedFilters] = useState({});
    const columnRefs = useRef({});
    const [columnWidths, setColumnWidths] = useState({});

    useEffect(() => {
        if (processId && !isFirstRender.current) fetchReportData();
    }, [processId]);

    useEffect(() => {
        if (initialData && Object.keys(initialData).length > 0) setReportStates(initialData);
    }, [initialData]);

    useEffect(() => {
        const widths = {};
        for (const key in columnRefs.current) {
            if (columnRefs.current[key]) widths[key] = columnRefs.current[key].offsetWidth;
        }
        setColumnWidths(widths);
    }, [parameters, reportData]);

    const fetchReportData = async () => {
        const response = await getMartketRiskReportRFSpecific({
            year: executionYear,
            month: executionMonth,
            process_id: processId,
        });
        if (response) setReportStates(response);
    };

    const setReportStates = (data) => {
        if (data.current_values) {
            const { parameters, current_values } = data;
            const sortedParams = Object.keys(parameters).sort(
                (a, b) => parameters[a].column_order - parameters[b].column_order
            );
            setParameters(sortedParams.map((param) => ({ key: param, name: parameters[param].name })));
            setFilters(Object.fromEntries(sortedParams.map((param) => [param, ''])));
            setExpandedFilters(Object.fromEntries(sortedParams.map((param) => [param, false])));
            setReportData(current_values);
        }
    };

    const handleFilterChange = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };

    const handleClearFilter = (key) => {
        setFilters({ ...filters, [key]: '' });
    };

    const toggleFilter = (key) => {
        const isCurrentlyExpanded = expandedFilters[key];
        setExpandedFilters({ ...expandedFilters, [key]: !isCurrentlyExpanded });
        if (isCurrentlyExpanded) setFilters({ ...filters, [key]: '' });
    };

    const filteredData = reportData.filter((row) =>
        parameters.every((param) =>
            canFilter.includes(param.key)
                ? String(row[param.key] || '').toLowerCase().includes(filters[param.key].toLowerCase())
                : true
        )
    );

    if (loading.getMartketRiskReportRFSpecific || loading.getLastExecution) return <LoadingSpinner />;
    if (inLineErrors.getMartketRiskReportRFSpecific) return <InLineError errorMessage={inLineErrors.getMartketRiskReportRFSpecific} />;

    return (
        <TableContainer component={Paper} sx={{ overflowX: isBelowLgScreen ? 'auto' : 'hidden', height: reportData.length < 30 ? 'auto' : 'calc(100vh - 100px)' }}>
            <Table stickyHeader size="small">
                <TableHead>
                    <InformesTableRow>
                        {parameters.map((param) => (
                            <InformesTableCell
                                key={param.key}
                                ref={(el) => (columnRefs.current[param.key] = el)}
                                sx={{
                                    textAlign: 'left',
                                    verticalAlign: 'top',
                                    minWidth: columnWidths[param.key] ? `${columnWidths[param.key]}px` : 'auto',
                                    maxWidth: columnWidths[param.key] ? `${columnWidths[param.key]}px` : 'auto',
                                }}
                            >
                                <Stack direction="column" sx={{ mx: '2px' }}>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                        {param.name}
                                        {canFilter.includes(param.key) && (
                                            <IconButton size="small" onClick={() => toggleFilter(param.key)}>
                                                {expandedFilters[param.key] ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                                            </IconButton>
                                        )}
                                    </Stack>
                                    <Collapse in={expandedFilters[param.key]} timeout="auto" unmountOnExit>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            value={filters[param.key]}
                                            onChange={(e) => handleFilterChange(param.key, e.target.value)}
                                            placeholder={`Filtrar ${param.name}`}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton size="small" onClick={() => handleClearFilter(param.key)}>
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                ),
                                                style: { fontSize: '13px', height: '24px' },
                                            }}
                                            sx={{
                                                height: '28px',
                                                width: '100%',
                                                '& .MuiOutlinedInput-root': { padding: 0 },
                                                '& input': { padding: '4px' },
                                            }}
                                        />
                                    </Collapse>
                                </Stack>
                            </InformesTableCell>
                        ))}
                    </InformesTableRow>
                </TableHead>
                <TableBody>
                    {filteredData.length === 0 ? (
                        <InformesTableRow>
                            <InformesTableCell colSpan={parameters.length} sx={{ textAlign: 'center' }}>
                                No hay datos para mostrar
                            </InformesTableCell>
                        </InformesTableRow>
                    ) : (
                        filteredData.map((row, rowIndex) => (
                            <InformesTableRow key={rowIndex}>
                                {parameters.map((param, idx) => (
                                    <InformesTableCell key={idx} sx={{ textAlign: 'left' }}>
                                        {param.type === 'number' && row[param.key] !== undefined
                                            ? formatNumber(row[param.key])
                                            : row[param.key] || ''}
                                    </InformesTableCell>
                                ))}
                            </InformesTableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
