import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Checkbox, FormControl, MenuItem, Select, CircularProgress, Tooltip } from '@mui/material';
import { SELECT_TEXT } from '../theme';

const inputProps = {
    sx: {
        padding: '4px',
        paddingLeft: '14px',
        fontSize: '14px',
        fontWeight: 400,
    }
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '245px',
        },
    },
    MenuListProps: {
        autoFocusItem: false,
    },
};

export default function CustomSelect({
    horizontal = false,
    title = "",
    name,
    multiple = false,
    value,
    onChange,
    width,
    maxWidth,
    selectItems = [],
    loading = false,
    tooltipItems = {},
    minWidthLargestItem = false
}) {
    const display = horizontal ? 'flex' : 'block';
    const headerMarginTop = horizontal ? '10px' : 0;
    const headerMarginRight = horizontal ? '12px' : 0;
    const selectMarginTop = horizontal ? '3px' : '6px';

    const [calculatedMinWidth, setCalculatedMinWidth] = useState(0);

    const calculateMinWidth = () => {
        // Crear un canvas invisible para medir texto
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '14px Roboto'; // Asegúrate de usar la misma fuente que Material-UI usa para `MenuItem`

        let maxWidth = 0;
        selectItems.forEach((item) => {
            const textWidth = context.measureText(item).width;
            if (textWidth > maxWidth) {
                maxWidth = textWidth;
            }
        });

        // Agrega un padding adicional para evitar que el texto toque los bordes
        return maxWidth + 48; // 48px adicionales para padding interno y el ícono del dropdown
    };

    useEffect(() => {
        if (selectItems.length > 0) {
            const minWidth = calculateMinWidth();
            setCalculatedMinWidth(minWidth);
        }
    }, [selectItems]);

    const handleSelectChange = (event) => {
        const selected = event.target.value;
        if (multiple) {
            if (selected.includes("Todas")) {
                if (value.length === selectItems.length) {
                    // Deselect all
                    onChange({ target: { name, value: [] } });
                } else {
                    // Select all
                    onChange({ target: { name, value: selectItems } });
                }
            } else {
                // Update selection without "Todas"
                onChange({ target: { name, value: selected.filter(item => item !== "Todas") } });
            }
        } else {
            onChange(event);
        }
    };

    const renderValue = (selected) => {
        if (!selected || selected.length === 0) {
            return "Seleccione una opción";
        }

        if (multiple) {
            if (selected.length === selectItems.length) {
                return "Todas";
            }
            return selected.length > 2
                ? `${selected.length} seleccionadas`
                : selected.join(', ');
        }
        return selected;
    };

    const renderTooltipContent = (content) => {
        if (!content) return null;

        return content.split("{break}").map((segment, index) => (
            <React.Fragment key={index}>
                {segment.split(/(\{bold\}.*?\{bold\})/g).map((part, i) => {
                    if (part.startsWith("{bold}") && part.endsWith("{bold}")) {
                        const boldText = part.slice(6, -6);
                        return (
                            <b key={i} style={{ fontWeight: 'bold' }}>
                                {boldText}
                            </b>
                        );
                    }
                    return part;
                })}
                {index < content.split("{break}").length - 1 && <br />}
            </React.Fragment>
        ));
    };

    return (
        <Box display={display}>
            <Typography sx={{ ...SELECT_TEXT, mt: headerMarginTop, mr: headerMarginRight }}>
                {title}
            </Typography>
            <FormControl fullWidth>
                <Select
                    name={name}
                    multiple={multiple}
                    value={value}
                    onChange={handleSelectChange}
                    displayEmpty
                    renderValue={renderValue}
                    size="small"
                    color="primary"
                    sx={{
                        fontWeight: 400,
                        mt: selectMarginTop,
                        minWidth: minWidthLargestItem ? calculatedMinWidth : 'auto',
                        maxWidth: maxWidth,
                        backgroundColor: 'white',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                    inputProps={inputProps}
                    MenuProps={MenuProps}
                >
                    {loading ? (
                        <MenuItem
                            sx={{
                                pointerEvents: 'none',
                                color: '#000',
                                fontWeight: 400,
                                textAlign: 'center',
                                fontStyle: 'italic',
                                height: '14px',
                            }}
                        >
                            <CircularProgress size={20} sx={{ marginRight: '10px' }} />
                            Cargando...
                        </MenuItem>
                    ) : selectItems.length === 0 ? (
                        <MenuItem
                            sx={{
                                pointerEvents: 'none',
                                color: '#000',
                                fontWeight: 400,
                                textAlign: 'center',
                                fontStyle: 'italic',
                                height: '14px',
                            }}
                        >
                            No hay resultados
                        </MenuItem>
                    ) : (
                        [
                            multiple && (
                                <MenuItem
                                    key="all"
                                    value="Todas"
                                    sx={{ fontSize: '14px', fontWeight: 400, padding: '4px', pl: '14px', pr: '14px' }}
                                >
                                    <Checkbox
                                        size="small"
                                        checked={value.length === selectItems.length}
                                        indeterminate={value.length > 0 && value.length < selectItems.length}
                                        sx={{ height: '14px', pl: 0 }}
                                    />
                                    Todas
                                </MenuItem>
                            ),
                            ...selectItems.map((elem) => (
                                <MenuItem
                                    key={elem}
                                    value={elem}
                                    sx={{ fontSize: '14px', fontWeight: 400, padding: '4px', pl: '14px', pr: '14px' }}
                                >
                                    {tooltipItems[elem] ? (
                                        <Tooltip
                                            title={
                                                <Box sx={{ fontSize: '12px', lineHeight: '1.5' }}>
                                                    {renderTooltipContent(tooltipItems[elem])}
                                                </Box>
                                            }
                                            placement="right"
                                            arrow
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {multiple && (
                                                    <Checkbox
                                                        size="small"
                                                        sx={{ height: '14px', pl: 0 }}
                                                        checked={value.includes(elem)}
                                                    />
                                                )}
                                                {elem}
                                            </Box>
                                        </Tooltip>
                                    ) : (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {multiple && (
                                                <Checkbox
                                                    size="small"
                                                    sx={{ height: '14px', pl: 0 }}
                                                    checked={value.includes(elem)}
                                                />
                                            )}
                                            {elem}
                                        </Box>
                                    )}
                                </MenuItem>
                            )),
                        ]
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}

