import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BLUE, DARK_GREY, GREY, SUBTITLE_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import CustomSelect from '../../../components/CustomSelect';
import { useErrors } from '../../../hooks/useErrors';
import LoadingSpinner from '../../../components/LoadingSpinner';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-root': {
        fontSize: '13px',
        padding: 0,
    },
    '&.MuiTableCell-head': {
        fontWeight: 700,
        borderBottom: `solid 1px ${BLUE}`,
    },
    '&.MuiTableCell-body': {
        padding: '4px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-body': {
        border: `solid 2px ${DARK_GREY}`
    },
    '&:last-child td': {
        fontWeight: 700,
        backgroundColor: GREY,
    },
}));

const displayIndicators = ["Exposición Original", "APRs"]
export default function RiskPonderationEADsTable({ data }) {
    const { loading } = useErrors();
    const [parameters, setParameters] = useState([]);
    const [totals, setTotals] = useState({});
    const [usedPonderations, setUsedPonderations] = useState([]);
    const [selectedIndicator, setSelectedIndicator] = useState("Exposición Original");
    const formatNumber = (value) => value.toLocaleString("es-ES", { minimumFractionDigits: 0, maximumFractionDigits: 2 });

    useEffect(() => {
        if (data.parameters && data.totals && data.used_ponderations) {
            setParameters(data.parameters);
            setTotals(data.totals);
            setUsedPonderations(data.used_ponderations);
        }
    }, [data]);

    const handleOnChangeDisplayIndicator = (event) => {
        setSelectedIndicator(event.target.value);
    }

    return (
        <Box>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={9}>
                    <Typography color={BLUE} sx={SUBTITLE_TEXT}>
                        EADs y ponderaciones de riesgo de cada categoría regulatoria
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                    <CustomSelect
                        title={'Indicador:'}
                        selectItems={displayIndicators}
                        value={selectedIndicator}
                        onChange={handleOnChangeDisplayIndicator}
                        horizontal
                    />
                </Grid>
            </Grid>
            {loading.getCdMCreditRiskCategoriesPonderations || loading.getLastExecution ? 
                <LoadingSpinner /> 
            :
                Object.keys(data).length === 0 ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: '50px' }}>
                        <Typography sx={{ fontWeight: "bold" }}>No hay datos disponibles</Typography>
                    </Box>
                :   
                    <TableContainer 
                        component={Paper}
                        sx={{ overflowX: 'auto'}}
                    >
                        <Table sx={{  minWidth: '1250px' }}>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell rowSpan={2} align='center' width={"20%"}>
                                        Categorías de exposición
                                    </StyledTableCell >
                                    <StyledTableCell colSpan={usedPonderations && usedPonderations.length} align='center' width={"70%"}>
                                        Ponderación de riesgo
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align='right' width={"5%"}>
                                        Total
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    {usedPonderations && usedPonderations.map((ponderation) => (
                                        <StyledTableCell
                                            key={ponderation}
                                            align='right'
                                            width={`${70 / usedPonderations.length}%`}
                                        >
                                            {ponderation}
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {parameters && parameters.map((row, rowIndex) => (
                                    <StyledTableRow key={rowIndex}>
                                        <StyledTableCell>{row.category_name}</StyledTableCell>
                                        {usedPonderations.map((ponderation, colIndex) => (
                                            <StyledTableCell key={colIndex} align='right'>
                                                {row.values[ponderation] ?
                                                    row.values[ponderation][selectedIndicator] !== 0 ? formatNumber(row.values[ponderation][selectedIndicator]) : '-'
                                                    : '-'
                                                }
                                            </StyledTableCell>
                                        ))}
                                        <StyledTableCell align='right'>
                                            {row.total ? 
                                                row.total[selectedIndicator] !== 0 ? formatNumber(row.total[selectedIndicator]) : '-' : '-'}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                <StyledTableRow>
                                    <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: GREY }}>
                                        Total
                                    </StyledTableCell>
                                    {usedPonderations && usedPonderations.map((ponderation, index) => (
                                        <StyledTableCell key={index} align='right' style={{ fontWeight: 'bold', backgroundColor: GREY }}>
                                            {totals[ponderation] 
                                                ? totals[ponderation][selectedIndicator] !== 0 ? formatNumber(totals[ponderation][selectedIndicator]) : '-'
                                                : '-'}
                                        </StyledTableCell>
                                    ))}
                                    <StyledTableCell align='right' style={{ fontWeight: 'bold', backgroundColor: GREY }}>
                                        {totals && totals.total ? totals.total[selectedIndicator] !== 0 ? formatNumber(totals.total[selectedIndicator]) : '-' : '-'}
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </Box>
    );
}
