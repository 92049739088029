export const HOME_PATH = '/';
export const ENQUIRY_PATH = '/consulta';
export const PROCESSES_PATH = '/processes';
export const NEW_EXECUTION_PATH = '/processes/new-execution';
export const SIMULATIONS_PATH = '/simulations';
export const NEW_SIMULATION_PATH = '/simulations/new-simulation';
export const CDM_EXECUTIONS_PATH = '/cdm-executions';
export const REPORTS_KEY_INDICATORS_PATH = '/reports/key-indicators';
export const REPORTS_CREDIT_RISK_SUMMARY_PATH = '/reports/credit-risk/summary';
export const REPORTS_CREDIT_RISK_CRR2_VS_CRR3_PATH = '/reports/credit-risk/crr2-vs-crr3';
export const REPORTS_CREDIT_RISK_IRB_SUMMARY_PATH = '/reports/credit-risk-IRB/summary';
export const REPORTS_MARKET_RISK_PATH = '/reports/market-risk';
export const REPORTS_OPERATIONAL_RISK_PATH = '/reports/operational-risk';
export const CONFIGURATION_PATH = '/configuracion';