import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Header from "./components/Header";
import { theme } from "./theme";
import CDMExecutions from "./views/cdm-executions/CDMExecutions";
import NewExecution from "./views/new-execution/NewExecution";
import Processes from "./views/processes/Processes";
import CreditRiskReport from "./views/reports/credit-risk/CreditRiskReport";
import CreditRiskReportCRR2vsCRR3 from "./views/reports/credit-risk/CreditRiskReportCRR2vsCRR3";
import Configuration from "./views/configuration/Configuration";
import { HOME_PATH, PROCESSES_PATH, NEW_EXECUTION_PATH,
    CDM_EXECUTIONS_PATH, REPORTS_CREDIT_RISK_SUMMARY_PATH, CONFIGURATION_PATH,
    REPORTS_KEY_INDICATORS_PATH,
    REPORTS_OPERATIONAL_RISK_PATH,
    REPORTS_MARKET_RISK_PATH,
    REPORTS_CREDIT_RISK_CRR2_VS_CRR3_PATH,
    REPORTS_CREDIT_RISK_IRB_SUMMARY_PATH} from './config/Paths';
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { AuthProvider } from "./context/AuthContext";
import { ErrorProvider } from "./context/ErrorContext";
import ErrorDialog from "./components/ErrorDialog";
import { ProcessesProvider } from "./context/ProcessesContext";
import OperationalRiskReport from "./views/reports/operational-risk/OperationalRiskReport";
import KeyIndicatorsReport from "./views/reports/key-indicators/KeyIndicatorsReport";
import MarketRiskReport from "./views/reports/market-risk/MarketRiskReport";
import { isAuthEnabled } from './config/AuthConfig';
import CreditRiskIRBReport from "./views/reports/credit-risk-IRB/CreditRiskIRBReport";

export default function App() {
    const { instance, accounts, inProgress } = useMsal();
    const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

    useEffect(() => {
        if (isAuthEnabled && accounts.length === 0 && inProgress === "none") {
            instance.loginRedirect().catch(e => {
                console.error(e);
            });
        }
    }, [accounts, instance, inProgress]);

    return (
        <BrowserRouter>
            <ErrorProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {isAuthEnabled ? (
                        <AuthenticatedTemplate>
                            <AuthProvider>
                                <ErrorDialog />
                                <Header />
                                <SentryRoutes>
                                    <Route path={HOME_PATH} element={<Navigate to={PROCESSES_PATH} replace />} />
                                    <Route 
                                        path={PROCESSES_PATH} 
                                        element={
                                            <React.Fragment key="processes">
                                                <ProcessesProvider>
                                                    <Processes />
                                                </ProcessesProvider>
                                            </React.Fragment>
                                        } 
                                    />
                                    <Route path={NEW_EXECUTION_PATH} element={<NewExecution />} />
                                    <Route 
                                        path={CDM_EXECUTIONS_PATH} 
                                        element={
                                            <React.Fragment key="cdmExecutions">
                                                <ProcessesProvider>
                                                    <CDMExecutions />
                                                </ProcessesProvider>
                                            </React.Fragment>
                                        } 
                                    />
                                    <Route 
                                        path={REPORTS_KEY_INDICATORS_PATH} 
                                        element={
                                            <React.Fragment key="keyIndicatorsReport">
                                                <ProcessesProvider>
                                                    <KeyIndicatorsReport />
                                                </ProcessesProvider>
                                            </React.Fragment>
                                        } 
                                    />
                                    <Route 
                                        path={REPORTS_CREDIT_RISK_SUMMARY_PATH} 
                                        element={
                                            <React.Fragment key="creditRiskReportSummary">
                                                <ProcessesProvider>
                                                    <CreditRiskReport />
                                                </ProcessesProvider>
                                            </React.Fragment>
                                        } 
                                    />
                                    <Route 
                                        path={REPORTS_CREDIT_RISK_CRR2_VS_CRR3_PATH} 
                                        element={
                                            <React.Fragment key="creditRiskReportCRR2vsCRR3">
                                                <ProcessesProvider>
                                                    <CreditRiskReportCRR2vsCRR3 />
                                                </ProcessesProvider>
                                            </React.Fragment>
                                        }
                                    />
                                    <Route 
                                        path={REPORTS_CREDIT_RISK_IRB_SUMMARY_PATH} 
                                        element={
                                            <React.Fragment key="creditRIskIRB">
                                                <ProcessesProvider>
                                                    <CreditRiskIRBReport />
                                                </ProcessesProvider>
                                            </React.Fragment>
                                        } 
                                    />
                                    <Route 
                                        path={REPORTS_MARKET_RISK_PATH} 
                                        element={
                                            <React.Fragment key="marketRisk">
                                                <ProcessesProvider>
                                                    <MarketRiskReport />
                                                </ProcessesProvider>
                                            </React.Fragment>
                                        } 
                                    />
                                    <Route 
                                        path={REPORTS_OPERATIONAL_RISK_PATH} 
                                        element={
                                            <React.Fragment key="operationalRisk">
                                                <ProcessesProvider>
                                                    <OperationalRiskReport />
                                                </ProcessesProvider>
                                            </React.Fragment>
                                        } 
                                    />
                                    <Route path={CONFIGURATION_PATH} element={<Configuration />} />
                                </SentryRoutes>
                            </AuthProvider>
                        </AuthenticatedTemplate>
                    ) : (
                        <>
                            <ErrorDialog />
                            <Header />
                            <SentryRoutes>
                                <Route path={HOME_PATH} element={<Navigate to={PROCESSES_PATH} replace />} />
                                <Route 
                                    path={PROCESSES_PATH} 
                                    element={
                                        <React.Fragment key="processes">
                                            <ProcessesProvider>
                                                <Processes />
                                            </ProcessesProvider>
                                        </React.Fragment>
                                    } 
                                />
                                <Route path={NEW_EXECUTION_PATH} element={<NewExecution />} />
                                <Route 
                                    path={CDM_EXECUTIONS_PATH} 
                                    element={
                                        <React.Fragment key="cdmExecutions">
                                            <ProcessesProvider>
                                                <CDMExecutions />
                                            </ProcessesProvider>
                                        </React.Fragment>
                                    } 
                                />
                                <Route 
                                    path={REPORTS_KEY_INDICATORS_PATH} 
                                    element={
                                        <React.Fragment key="keyIndicatorsReport">
                                            <ProcessesProvider>
                                                <KeyIndicatorsReport />
                                            </ProcessesProvider>
                                        </React.Fragment>
                                    } 
                                />
                                <Route 
                                    path={REPORTS_CREDIT_RISK_SUMMARY_PATH} 
                                    element={
                                        <React.Fragment key="creditRiskReportSummary">
                                            <ProcessesProvider>
                                                <CreditRiskReport />
                                            </ProcessesProvider>
                                        </React.Fragment>
                                    } 
                                />
                                <Route 
                                    path={REPORTS_CREDIT_RISK_CRR2_VS_CRR3_PATH} 
                                    element={
                                        <React.Fragment key="creditRiskReportCRR2vsCRR3">
                                            <ProcessesProvider>
                                                <CreditRiskReportCRR2vsCRR3 />
                                            </ProcessesProvider>
                                        </React.Fragment>
                                    }
                                />
                                <Route 
                                    path={REPORTS_MARKET_RISK_PATH} 
                                    element={
                                        <React.Fragment key="marketRisk">
                                            <ProcessesProvider>
                                                <MarketRiskReport />
                                            </ProcessesProvider>
                                        </React.Fragment>
                                    } 
                                />
                                <Route 
                                    path={REPORTS_OPERATIONAL_RISK_PATH} 
                                    element={
                                        <React.Fragment key="operationalRisk">
                                            <ProcessesProvider>
                                                <OperationalRiskReport />
                                            </ProcessesProvider>
                                        </React.Fragment>
                                    } 
                                />
                                <Route path={CONFIGURATION_PATH} element={<Configuration />} />
                            </SentryRoutes>
                        </>
                    )}
                </ThemeProvider>
            </ErrorProvider>
        </BrowserRouter>
    );
}
