import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { InformesTableCell, InformesTableRow } from "../../../components/CustomStyles";
import { margin, styled } from "@mui/system";
import { GREY } from "../../../theme";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const StyledTotalRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        backgroundColor: GREY,
        fontWeight: 'bold',
    },
}));

const expositions_data = {
    "columns": {
        "row_title": {
            "name": "",
            "column_order": 1
        },
        "date1": {
            "name": "01/09/2024",
            "column_order": 2
        },
        "date2": {
            "name": "01/06/2024",
            "column_order": 3
        },
        "date3": {
            "name": "01/03/2024",
            "column_order": 4
        },
        "date4": {
            "name": "01/12/2023",
            "column_order": 5
        }
    },
    "current_values": [
        {
            "row_title": "Capital disponible (importes)",
            "row_order": 1,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null
        },
        {
            "row_title": "Capital de nivel 1 ordinario (CET1) [phased in]",
            "row_order": 2,
            "level": 2,
            "date1": 2514092,
            "date2": 2479011,
            "date3": 2421374,
            "date4": 2398322,
        },
        {
            "row_title": "Capital de nivel 1 ordinario (CET1) [fully loaded]",
            "row_order": 3,
            "level": 2,
            "date1": 2506135,
            "date2": 2469817,
            "date3": 2410560,
            "date4": 2375314
        },
        {
            "row_title": "Capital de nivel 1 (T1) [phased in]",
            "row_order": 4,
            "level": 2,
            "date1": 2869993,
            "date2": 2829011,
            "date3": 2771374,
            "date4": 2748322
        },
        {
            "row_title": "Capital de nivel 1 (T1) [fully loaded]",
            "row_order": 5,
            "level": 2,
            "date1": 2862036,
            "date2": 2819817,
            "date3": 2760560,
            "date4": 2725314
         },
        {
            "row_title": "Capital total [phased in]",
            "row_order": 6,
            "level": 2,
            "date1": 3359508,
            "date2": 3329011,
            "date3": 3271374,
            "date4": 3248322
        },
        {
            "row_title": "Capital total [fully loaded]",
            "row_order": 7,
            "level": 2,
            "date1": 3351551,
            "date2": 3319817,
            "date3": 3260560,
            "date4": 3225314
        },
        {
            "row_title": "Activos ponderados por riesgo (importes)",
            "row_order": 8,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null
        },
        {
            "row_title": "Total activos ponderados por riesgo [phased in]",
            "row_order": 9,
            "level": 2,
            "date1": 18809436,
            "date2": 18712926,
            "date3": 18391397,
            "date4": 18609328
        },
        {
            "row_title": "Total activos ponderados por riesgo [fully loaded]",
            "row_order": 10,
            "level": 2,
            "date1": 18805477,
            "date2": 18708460,
            "date3": 18386214,
            "date4": 18598235
        },
        {
            "row_title": "Ratios de capital",
            "row_order": 11,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null
        },
        {
            "row_title": "Capital de nivel 1 ordinario (CET1) (en porcentaje del importe de la exposición al riesgo) [phased in]",
            "row_order": 12,
            "level": 2,
            "date1": "13,36%",
            "date2": "13,24%",
            "date3": "13,16%",
            "date4": "12,88%"
        },
        {
            "row_title": "Capital de nivel 1 ordinario (CET1) (en porcentaje del importe de la exposición al riesgo) [fully loaded]",
            "row_order": 13,
            "level": 2,
            "date1": "13,32%",
            "date2": "13,20%",
            "date3": "13,11%",
            "date4": "12,77%"
        },
        {
            "row_title": "Capital de nivel 1 (T1) (en porcentaje del importe de la exposición al riesgo) [phased in]",
            "row_order": 14,
            "level": 2,
            "date1": "15,25%",
            "date2": "15,12%",
            "date3": "15,07%",
            "date4": "14,77%"
        },
        {
            "row_title": "Capital de nivel 1 (T1) (en porcentaje del importe de la exposición al riesgo) [fully loaded]",
            "row_order": 15,
            "level": 2,
            "date1": "15,21%",
            "date2": "15,07%",
            "date3": "15,01%",
            "date4": "14,65%"
        },
        {
            "row_title": "Capital total (en porcentaje del importe de la exposición al riesgo) [phased in]",
            "row_order": 16,
            "level": 2,
            "date1": "17,86%",
            "date2": "17,79%",
            "date3": "17,79%",
            "date4": "17,46%"
        },
        {
            "row_title": "Capital total (en porcentaje del importe de la exposición al riesgo) [fully loaded]",
            "row_order": 17,
            "level": 2,
            "date1": "17,82%",
            "date2": "17,75%",
            "date3": "17,73%",
            "date4": "17,34%"
        },
        {
            "row_title": "Ratio de apalancamiento",
            "row_order": 18,
            "level": 1,
            "date1": null,
            "date2": null,
            "date3": null,
            "date4": null
        },
        {
            "row_title": "Medida de la exposición total correspondiente a la ratio de apalancamiento",
            "row_order": 19,
            "level": 2,
            "date1": 45751598,
            "date2": 46780412,
            "date3": 46687482,
            "date4": 47431442
        },
        {
            "row_title": "Ratio de apalancamiento [phased in]",
            "row_order": 20,
            "level": 2,
            "date1": "6,27%",
            "date2": "6,05%",
            "date3": "5,94%",
            "date4": "5,79%"
        },
        {
            "row_title": "Ratio de apalancamiento [fully loaded]",
            "row_order": 21,
            "level": 2,
            "date1": "6,26%",
            "date2": "6,03%",
            "date3": "5,91%",
            "date4": "5,65%"
        }
    ]
}


const getRowTitleStyle = (level) => {
    switch (level) {
        case 1:
            return { fontSize: "13px", fontWeight: "bold" };
        case 2:
            return { fontSize: "13px", fontWeight: 450 };
        case 3:
            return { fontSize: "13px", textDecoration: "underline" };
        case 4:
            return { fontSize: "13px", marginLeft: "200px" };
        case 5:
            return { fontSize: "13px", fontWeight: "bold" };
        default:
            return {};
    }
  };

  const getRowIndent = (level) => {
    switch (level) {
      case 1: return 0;  // Sin margen
      case 2: return 20;  // Pequeño margen
      case 3: return 40;  // Mayor margen
      case 4: return 60;  // Aumenta más
      case 5: return 0;  // Igual que level 4 (Total)
      default: return 0;
    }
  };


export default function PrincipalRatiosTable() {
    const formatNumber = useNumberFormatter();
    const orderedColumns = Object.entries(expositions_data.columns)
        .sort((a, b) => a[1].column_order - b[1].column_order)
        .map(([key, value]) => ({ key, name: value.name }));

    // Ordenar las filas según `row_order`
    const sortedRows = [...expositions_data.current_values].sort((a, b) => a.row_order - b.row_order);

    return (
        <TableContainer component={Paper}>
          <Table >
            {/* Encabezado de la tabla */}
            <TableHead>
              <InformesTableRow>
                {orderedColumns.map(({ key, name }) => (
                  <InformesTableCell key={key} style={{ width: key === "row_title" ? '40%' : '8%', textAlign: "right",  verticalAlign: "top"}}>
                    {name}
                  </InformesTableCell>
                ))}
              </InformesTableRow>
            </TableHead>
    
            {/* Cuerpo de la tabla */}
            <TableBody>
              {sortedRows.map((row, index) =>
                <InformesTableRow key={index}>
                    {orderedColumns.map(({ key }) => (
                        <InformesTableCell 
                            key={key} 
                            style={{
                                ...(key === "row_title" ? getRowTitleStyle(row.level) : {}),
                                paddingLeft: key === "row_title" ? `${getRowIndent(row.level)}px` : "inherit",
                                textAlign: key === "row_title" ? "left" : "right"
                                }}
                        >
                            {typeof row[key] === 'number' ? formatNumber(row[key]) : row[key]}
                        </InformesTableCell>
                    ))}
                </InformesTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      );
};
