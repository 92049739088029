import { useAuth } from './useAuth';
import { useErrors } from './useErrors';
import { useState, useEffect, useCallback, useRef } from 'react';
import { isAuthEnabled } from '../config/AuthConfig';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const useApi = () => {
    let accessToken, acquireToken, isTokenExpired;

    if (isAuthEnabled) {
        // Solo usa autenticación si está habilitada
        ({ accessToken, acquireToken, isTokenExpired } = useAuth());
    }

    const accessTokenRef = useRef(accessToken);

    useEffect(() => {
        if (isAuthEnabled) {
            accessTokenRef.current = accessToken;
        }
    }, [accessToken]);

    const apiCall = useCallback(async (method, endpoint, body = null) => {
        if (isAuthEnabled) {
            if (isTokenExpired()) {
                console.log("Token expired. Refreshing token.");
                await acquireToken();
                await delay(3000);
            }

            let retries = 4;
            while (accessTokenRef.current === null && retries > 0) {
                if (retries === 2) {
                    await acquireToken();
                }
                retries--;
            }

            if (accessTokenRef.current === null) {
                console.error('AccessToken not provided for the API call.');
                return { success: false, error: 'AccessToken not provided.' };
            }
        }

        const headers = new Headers();
        if (isAuthEnabled) {
            headers.append('Authorization', `Bearer ${accessTokenRef.current}`);
        }

        let isFormData = body instanceof FormData;

        if (!isFormData) {
            headers.append('Content-Type', 'application/json');
        }

        const options = {
            method: method,
            headers: headers,
            mode: 'cors',
            credentials: isAuthEnabled ? 'include' : 'same-origin', // Si no hay autenticación, no incluye credenciales
        };

        if (body) {
            options.body = isFormData ? body : JSON.stringify(body);
        }

        try {
            const response = await fetch(endpoint, options);
            if (response.status === 200 || response.status === 202) {
                const data = await response.json();
                return { success: true, data: data, statusCode: response.status };
            } else if (response.status === 304) {
                return { success: true, data: null, statusCode: 304, message: 'No changes. Not Modified' };
            } else if (response.status === 401 || response.status === 403) {
                const data = await response.json();
                console.log('AccessToken expired.');
                if (isAuthEnabled) {
                    isTokenExpired();
                }
                return { success: false, data: data, statusCode: response.status };
            } else {
                const data = await response.json();
                return { success: false, data: data, statusCode: response.status };
            }
        } catch (error) {
            console.error('Error in API call:', error);
            return { success: false, error: error };
        }
    }, [accessToken]);

    const addQueryParameters = (fileUrl, queryParamsDict) => {
        const queryParams = [];
        for (const key in queryParamsDict) {
            const value = queryParamsDict[key];
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    queryParams.push(`${key}=${encodeURIComponent(value.join(','))}`);
                }
            } else if (value !== "" && value !== null && value !== undefined) {
                queryParams.push(`${key}=${encodeURIComponent(value)}`);
            }
        }
        const queryString = queryParams.join('&');
        if (queryString) {
            fileUrl.search = `?${queryString}`;
        }
        return fileUrl;
    };

    const handleDownload = async (fileUrl, fileDownloadName) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            if (isAuthEnabled) {
                headers['Authorization'] = `Bearer ${accessToken}`;
            }

            const options = {
                method: 'GET',
                withCredentials: true,
                credentials: isAuthEnabled ? 'include' : 'same-origin',
                headers: headers,
                mode: 'cors',
            };

            const response = await fetch(fileUrl, options);

            if (response.status !== 200 && response.status !== 202) {
                return { success: false };
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileDownloadName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.parentNode.removeChild(link);
            return { success: true };
        } catch (error) {
            console.error('Error en la descarga:', error);
            return { success: false };
        }
    };

    return { apiCall, addQueryParameters, handleDownload };
};
